<template>
  <v-card elevation="1" class="mb-4" v-if="!this.isProductCompany() || invoice.stock_spent != null">
    <v-card-text>
      <h6 class="text-h6 black--text">Importes</h6>
      <p v-if="!isViewModal && !isEditable()" class="text-body-2">Ingrese los importes incluidos en la factura</p>

      <v-row>
        <v-col md="12" v-if="!isViewModal && !isEditable()">
          <v-btn small color="blue-grey" class="pl-4 text-white" @click="addCustomDetail()">
            <v-icon big color="green"> add </v-icon>
            <span>Agregar item</span>
          </v-btn>
        </v-col>
        <v-col md="12" v-else>
          <v-label style="color: #da0f0f;">{{getMessageValidation()}}</v-label>
        </v-col>
        <v-col md="12" class="px-4 pb-4">
          <v-data-table :headers="header_custome" :items="invoice.items_custom" style="border: solid 1px #1c1c1c"
            :hide-default-footer="true" :items-per-page="100">
            <template v-slot:item="props">
              <tr>
                <td v-if="isProductCompany() && invoice.stock_spent != null && invoice.stock_spent">
                  <v-combobox v-model="props.item.product_presentation" data-vv-as="producto"
                    v-validate="'required|combobox_required'"
                    :name="'product_presentation_' + props.index" :item-text="label" item-value="id"
                    :items="presentations" label="Producto" autocomplete="off" clearable
                    v-on:change="productChangeCombo($event, props.index)" :disabled="isViewModal || isEditable()"></v-combobox>
                  <span class="red--text">{{ errors.first("product_presentation_" + props.index) }}</span>
                </td>
                <td :style="invoice.stock_spent ? 'width: 20%' : 'width: 35%'">
                  <v-text-field :disabled="isViewModal || isEditable()" autocomplete="off" :name="'descripcion' + props.index" placeholder="Descripción*"
                    dense type="text" v-model="props.item.descripcion" class="mt-3" />                  
                  <span class="red--text">{{ errors.first('descripcion_' + props.index) }}</span>
                </td>
                <td>
                  <v-combobox v-model="props.item.sub_cost_center" data-vv-as="Subcentro de costo"
                    :name="'sub_cost_center' + props.index" item-text="label" item-value="id" :items="sub_costs_centers"
                    label="Subcentro de Costo*" v-validate="'required|combobox_required'" autocomplete="off"
                    :disabled="isViewModal || isEditable()"></v-combobox>
                  <span class="red--text">{{ errors.first('sub_cost_center' + props.index) }}</span>
                </td>

                <td style="width: 100px">
                  <v-text-field :disabled="isViewModal || isEditable()" autocomplete="off" :name="'quantity' + props.index" placeholder="Cantidad*" dense
                    v-validate="'decimal'" min="0" type="number" v-model="props.item.quantity" class="mt-3" />
                  <span class="red--text">{{ errors.first('quantity' + props.index) }}</span>
                </td>
                <td style="width: 150px">
                  <v-text-field min="0" :disabled="isViewModal || isEditable()" autocomplete="off" :name="'price_' + props.index" placeholder="Precio*"
                    dense v-validate="'required|decimal'" v-model="props.item.price" class="mt-3" data-vv-as="precio" />
                  <span class="red--text">{{ errors.first('price_' + props.index) }}</span>
                </td>
                <td style="width: 100px">
                  <v-text-field data-vv-as="Bonificación" :disabled="isViewModal || isEditable()" autocomplete="off"
                    :name="'discount_per_' + props.index" placeholder="Bonificación" dense
                    v-validate="'required|decimal|between:0,100'" min="0" type="number"
                    v-model="props.item.discount_per" class="mt-3" @change="updateDiscountImp(props.item)" />
                  <span class="red--text">{{ errors.first("discount_per_" + props.index) }}</span>
                </td>
                <td style="width: 100px">
                  <div>
                    <v-combobox v-model="props.item.iva" data-vv-as="iva" :name="'iva' + props.index" item-text="Desc" item-value="Id"
                      :items="ivas" label="IVA*" autocomplete="off" :loading="loading_combos['ivas']"
                      v-validate="'required|combobox_required'" :disabled="isViewModal || isEditable()"></v-combobox>
                    <span class="red--text">{{ errors.first('iva' + props.index) }}</span>
                  </div>
                </td>
                <td class="text-right">{{ showTotalCustomeItem(props.item) }}</td>
                <td class="text-center">
                  <v-tooltip bottom v-if="!isViewModal && invoice.purchase_order == null && invoice.total_paid == 0">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small color="red" @click="removeDetail(props.item, 'custom')">
                        indeterminate_check_box </v-icon>
                    </template>
                    <span>Remover</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Constant from "@/constants/sections";
import { spentService } from "@/libs/ws/spentService";
import { formatUtils } from "@/libs/utils/formatUtils";
import { generalService } from "@/libs/ws/generalService";
import { vouchersService } from "@/libs/ws/vouchersService";
import SettingsMixin from "@/mixins/SettingsMixin.vue";
export default {
  created() {
    this.$validator = this.validator;
  },
  mixins: [SettingsMixin],
  components: {},
  props: {
    open: Boolean,
    invoice: Object,
    loading_combos: Object,
    loadItemsFromInvoiceCancel: Function,
    validator: Object,
    isViewModal: {
      type: Boolean,
      default: false,
    },
    purchase_order: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    sub_costs_centers: [],
    old_stock_spent: null, //Sirve para saber si cambio el indicador de comprobante con stock
    presentations: [],
    ivas: [],
    emitter_selected: null,
    header_custome: [
      { text: "Descripción", value: "descripcion", sortable: false },
      { text: "Subcentro de Costo", value: "sub_cost_center", sortable: false },
      { text: "Cantidad", value: "quantity", sortable: false, class: "bg-grey" },
      { text: "Precio", value: "price", sortable: false },
      { text: "Bonif. %", value: "discount_per", sortable: false },
      { text: "IVA", value: "iva", sortable: false, class: "bg-grey" },
      { text: "Total", value: "total", sortable: false },
      { text: "", value: "", sortable: false, class: "bg-grey" },
    ],
  }),
  watch: {
    open: function (val) {
      if (val) {
        this.errors.clear();
        this.$validator.reset();
        this.loadComboData();
      }
    },
    invoice: {
      handler: function (val) {
        if (!(this.invoice.id > 0) && this.invoice.stock_spent != this.old_stock_spent && this.old_stock_spent != null) {
          //Si cambio el stock_spent reinicio los items
          this.invoice.items_custom = [];
          this.old_stock_spent = this.invoice.stock_spent;
        }

        if (this.header_custome.length == 8 && this.invoice.stock_spent) {
          const producto_row = {
            text: "Producto",
            value: "product_presentation",
          };
          this.header_custome.splice(0, 0, producto_row);
        } else if (this.header_custome.length == 9 && !this.invoice.stock_spent) {
          this.header_custome.splice(0, 1);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.loadComboData();
  },
  methods: {
    isEditable() {
      return (this.invoice.id > 0 && this.invoice.purchase_order != null ) || this.invoice.total_paid > 0 || this.invoice.imp_credit_accumulated > 0 || (!this.invoice.is_invoice && this.invoice.cbtes_asoc != null && this.invoice.cbtes_asoc.length>0);
    },  
    getMessageValidation() {
      if(this.invoice.id > 0 && !this.isViewModal) { 
        if(this.invoice.purchase_order != null) {
          return "No se puede modificar los items ya que el comprobante tiene una orden de compra asociada."
        } 

        if(this.invoice.total_paid > 0 ) {
          return "No se puede modificar los items ya que el comprobante tiene pagos asociados."
        }

        if(this.invoice.imp_credit_accumulated > 0 ) {
          return "No se puede modificar los items ya que el comprobante tiene una nota de credito/debito asociada." 
        }

        if(!this.invoice.is_invoice && this.invoice.cbtes_asoc != null && this.invoice.cbtes_asoc.length>0) {
          return "No se puede modificar los items ya que el comprobante tiene comprobantes asociados."
        }
      }
    },
    loadComboData() {
      generalService.setSection(Constant.SECTION_SUB_COSTS_CENTERS);
      generalService
        .filter()
        .then((response) => {
          this.sub_costs_centers = response.result;
          this.loading = false;
        })
        .catch((e) => {
          console.log("sub_costs_centers.filter error");
          console.log(e);
          this.loading = false;
        });

      this.ivas = spentService.ivas();
      this.loading_combos["ivas"] = false;

      generalService.setSection(Constant.SECTION_PRODUCTS);
      generalService
        .query({ buyable: true }, Constant.SECTION_PRODUCTS + "/presentations")
        .then((response) => {
          this.presentations = response.result;
        })
        .catch((e) => {
          console.log("presentations.filter error");
        });
    },
    addCustomDetail() {
      if (!this.isProductCompany() || (this.invoice.stock_spent != null && !this.invoice.stock_spent))
        this.invoice.items_custom.push({
          descripcion: "",
          discount_imp: 0,
          discount_per: 0,
          sub_cost_center: null,
          quantity: 1,
          price: 0,
          iva: null,
        });
      else if (this.invoice.stock_spent != null && this.invoice.stock_spent) {
        this.invoice.items_custom.push({
          descripcion: "",
          discount_imp: 0,
          discount_per: 0,
          sub_cost_center: null,
          quantity: 1,
          price: 0,
          product_presentation: null,
          iva: null,
        });
      }
    },
    productChangeCombo(e, index) {
      if (this.invoice.items_custom[index].descripcion == "") {
        this.invoice.items_custom[index].descripcion = e.label;
        this.invoice.items_custom[index].sub_cost_center = e.sub_cost_center
      }

      if (this.invoice.receiver != null && this.invoice.receiver.iva_condition != undefined && this.invoice.receiver.iva_condition.id != 2 && e.default_iva_id != null) {
        this.invoice.items_custom[index].iva = e.default_iva_id;
      }
    },
    showTotalCustomeItem(item) {
      let totals = this.calculateCustomeItemTotals(item);
      return formatUtils.formatPrice(totals.total);
    },
    calculateCustomeItemTotals(item) {
      if (item.price == null || item.quantity == null)
        return {
          imp_neto: 0,
          imp_iva: 0,
        };

      this.updateDiscountImp(item);
      let imp_neto = formatUtils.formatPriceNumber(item.quantity * item.price - item.discount_imp);
      const iva_id = item.iva != null ? item.iva.Id : null;
      let imp_iva =
        vouchersService.NoIVAList().includes(iva_id) || iva_id == null ? 0.0 : (formatUtils.formatPriceNumber(imp_neto) * formatUtils.formatPriceNumber(item.iva.Desc.replace("%", ""))) / 100;
      return {
        imp_neto: imp_neto,
        imp_iva: formatUtils.formatPriceNumber(imp_iva),
        total: formatUtils.formatPriceNumber(imp_neto) + formatUtils.formatPriceNumber(imp_iva),
      };
    },
    removeDetail(item, colection_name) {
      if (colection_name == "custom") {
        const index_item = this.invoice.items_custom.indexOf(item);
        if (index_item > -1) this.invoice.items_custom.splice(index_item, 1);
      }
    },
    updateDiscountImp(item) {
      if (item.price != null && item.quantity != null && item.discount_per != null) item.discount_imp = (item.price * item.quantity * item.discount_per) / 100;
    },
    label(item) {
      if (this.isProductCompany()) return item.label;
      return item.name;
    },
  },
};
</script>
