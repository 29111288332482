<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" max-width="1200">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card style="background: rgb(221, 225, 230)">
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <v-stepper v-model="e1" alt-labels style="background: rgb(221, 225, 230)">
              <div class="row justify-content-center mt-4">
                <div class="col-md-12">
                  <v-stepper-header style="background: #fff; box-shadow: none">
                    <v-stepper-step :complete="e1 > 1" step="1"> Datos generales </v-stepper-step>

                    <v-divider></v-divider>

                    <v-stepper-step :complete="e1 > 2" step="2"> Presentacion </v-stepper-step>
                  </v-stepper-header>
                </div>
              </div>

              <v-stepper-items>
                <v-stepper-content step="1" class="pl-0 pr-0">
                  <v-card elevation="0" class="mb-4" style="min-height: 500px">
                    <v-card-text>
                      <h6 class="text-h6 black--text">Datos generales</h6>
                      <p class="text-body-2">Complete los datos del producto</p>

                      <div class="row justify-content-center">
                        <div class="col-md-6">
                          <v-text-field data-vv-as="Nombre" label="Nombre*" name="name" v-model="item.name" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                          <span class="red--text">{{ errors.first("name") }}</span>

                          <!--<v-textarea outlined v-model="item.description" data-vv-as="description" name="description" label="Descripción" :disabled="isViewModal()"></v-textarea>-->

                          <div class="row mt-3">
                            <div class="col-md-6">
                              <v-text-field data-vv-as="Código (SKU)" label="Código (SKU)" name="sku" v-model="item.sku" :disabled="isViewModal()"></v-text-field>
                              <span class="red--text">{{ errors.first("sku") }}</span>
                            </div>
                            <div class="col-md-6">
                              <v-combobox
                                v-model="item.default_iva_id"
                                data-vv-as="IVA"
                                name="default_iva_id"
                                item-text="Desc"
                                item-value="Id"
                                :items="ivas"
                                label="IVA %*"
                                autocomplete="off"
                                v-validate="'required|combobox_required'"
                                :disabled="isViewModal()"
                              ></v-combobox>
                              <span class="red--text">{{ errors.first("default_iva_id") }}</span>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6">
                              <v-combobox
                                v-model="item.category"
                                data-vv-as="Rubro"
                                name="category"
                                item-text="name"
                                item-value="id"
                                :items="categories"
                                label="Rubro*"
                                autocomplete="off"
                                v-validate="'required|combobox_required'"
                                :disabled="isViewModal()"
                                @change="CategoryComboChange()"
                              ></v-combobox>
                              <span class="red--text">{{ errors.first("category") }}</span>
                            </div>
                            <div class="col-md-6">
                              <v-combobox
                                v-model="item.subcategory"
                                data-vv-as="Subrubro"
                                name="subcategory"
                                v-validate="'combobox'"
                                item-text="name"
                                item-value="id"
                                :items="subcategories"
                                label="Subrubro"
                                autocomplete="off"
                                :disabled="isViewModal()"
                              ></v-combobox>
                              <span class="red--text">{{ errors.first("subcategory") }}</span>
                            </div>
                            <div class="col-md-6">
                              <v-combobox
                                v-model="item.type"
                                autocomplete="off"
                                data-vv-as="tipo"
                                name="type"
                                v-validate="'combobox'"
                                item-text="name"
                                item-value="id"
                                :items="product_types"
                                label="Tipo"
                                clearable
                                :disabled="isViewModal()"
                              ></v-combobox>
                              <span class="red--text">{{ errors.first("type") }}</span>
                            </div>
                            <div class="col-md-6">
                              <v-combobox
                                v-model="item.sub_cost_center"
                                :name="'sub_cost_center'"
                                item-text="label"
                                item-value="id"
                                v-validate="'combobox'"
                                :items="sub_costs_centers"
                                label="Subcentro de costo"
                                autocomplete="off"
                                :disabled="isViewModal()"
                                hint="Si el producto es visible para compras, elige el subcentro de costos para precargarlo al crear un comprobante"
                              ></v-combobox>
                            </div>
                            <div class="col-md-6">
                              <v-checkbox label="Habilitado" :disabled="isViewModal()" v-model="item.enabled"></v-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row justify-content-center mt-4">
                        <div class="col-md-12">
                          <v-btn class="float-right" color="primary" small @click="next"> Siguiente </v-btn>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-stepper-content>

                <v-stepper-content step="2" class="pl-0 pr-0">
                  <v-card elevation="0" class="mb-4">
                    <v-card-text style="min-height: 500px">
                      <TablePresentations v-if="dialog" :product="item" :open="dialog" :validator="$validator" :isViewModal="isViewModal()" />

                      <div class="row justify-content-center mt-4">
                        <div class="col-md-12">
                          <v-btn color="primary" @click="e1 = 1" small> Volver </v-btn>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>

          <v-card-actions style="position: absolute; bottom: 0; width: 100%">
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" v-if="!isViewModal()" :disabled="e1 <= 1 || item.presentations.length <= 0" :loading="loading">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { generalService } from "@/libs/ws/generalService";
import { spentService } from "@/libs/ws/spentService";
import ConstantSections from "@/constants/sections";
import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";
import TablePresentations from "./TablePresentations.vue";

export default {
  mixins: [ModalGenericMixin],
  components: { TablePresentations },
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,    
  },
  data() {
    return {
      show: false,
      categories: [],
      subcategories: [],
      ivas: [],
      e1: 1,
      product_types: [],
      sub_costs_centers: [],
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
      this.e1 = 1;
    },
  },
  mounted() {
    this.loadComboData();
    this.loadProductType();
  },
  methods: {
    next() {
      this.$validator.validate().then((isValid) => {
        if (!isValid) {
          //do whatever you need to do when something failed validation
        } else {
          this.e1 = 2;
        }
      });
    },
    isViewModal() {
      return this.formBtn == "";
    },
    CategoryComboChange(v) {
      generalService.filter({ category: this.item.category.id }, "subcategories").then((response) => {
        this.subcategories = response.result;
      });
    },
    loadProductType() {
      generalService.setSection(ConstantSections.SECTION_PRODUCT_TYPE);
      generalService
        .filter()
        .then((response) => {
          this.product_types = response.result;
        })
        .catch((e) => {
          console.log("product_types.filter error");
          console.log(e);
        });
    },
    loadComboData() {
      generalService.setSection(ConstantSections.SECTION_SUB_COSTS_CENTERS);
      generalService
        .filter()
        .then((response) => {
          this.sub_costs_centers = response.result;
          this.loading = false;
        })
        .catch((e) => {
          console.log("sub_costs_centers.filter error");
          console.log(e);
          this.loading = false;
        });

      this.ivas = spentService.ivas();
      generalService.filter({}, ConstantSections.SECTION_CATEGORIES).then((response) => {
        this.categories = response.result;
      });
    },
    createItem(e) {
      if (this.loading) return;

      this.service.setSection(ConstantSections.SECTION_PRODUCTS);

      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();

          this.loading = true;
          let data = JSON.parse(JSON.stringify(this.item));
          data["presentations_arr"] = data.presentations;
          delete data.presentations;

          if (this.item.id > 0) {
            this.service
              .update(data)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.service.setSection(ConstantSections.SECTION_PRODUCTS);

                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          } else {
            this.service
              .create(data)
              .then((response) => {
                if (response.status && response.result.id > 0) {
                  this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                  this.$emit("updateDialog", false);
                  this.service.setSection(ConstantSections.SECTION_PRODUCTS);

                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                console.log(response);
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          }
        }
      });
    },
  },
};
</script>
