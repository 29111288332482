<template>
  <v-card elevation="1" class="mb-4">
    <v-card-text>
      <h6 class="text-h6 black--text">Datos generales</h6>
      <p class="text-body-2">Ingrese la fecha del cobro y el cliente</p>
      <v-row class="mt-0">
        <v-col md="4">
          <v-combobox
            v-model="item.client"
            data-vv-as="cliente"
            autocomplete="off"
            item-text="name"
            item-value="id"
            :items="clients"
            v-validate="'required|combobox_required'"
            label="Cliente"
            class="pt-0 mt-0"
            clearable
            :disabled="isViewModal || openFromVoucher"
            filled
          ></v-combobox>
          <span class="red--text">{{ errors.first("cliente") }}</span>
        </v-col>
        <v-col md="4" class="mt-2">
          <DatePickerEsp
            :initToday="true"
            label="Fecha"
            :date="item.cbte_fch"
            :isRequired="true"
            name="cbte_fch"
            @setDate="
              (date) => {
                item.cbte_fch = date;
              }
            "
            :isViewModal="isViewModal"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { clientsService } from "@/libs/ws/clientsService";
import DatePickerEsp from "@/components/DatePickerEsp.vue";
export default {
  components: { DatePickerEsp },
  props: { item: Object, isViewModal: Boolean, openFromVoucher: Boolean, validator: Object },
  created() {
    this.$validator = this.validator;
  },
  data: () => ({ clients: [] }),
  watch: {},
  mounted() {
    this.getClients();
  },
  methods: {
    getClients() {
      clientsService.filter().then((response) => {
        this.clients = response.result;
      });
    },
  },
};
</script>
