<template>
  <div>
    <Title :pages="[{ icon: 'clock', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <v-card>
        <div class="row mb-2 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-5">
                <div class="col-md-3 pt-0">
                  <v-menu
                    ref="desde_selected"
                    v-model="desde_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="desde_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="disableDatefilter()"
                        v-model="desde_selected"
                        label="Desde"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="pt-0 mt-0"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="desde_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="desde_selected_open = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.desde_selected.save(desde_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-menu
                    ref="hasta_selected"
                    v-model="hasta_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="hasta_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :disabled="disableDatefilter()"
                        v-model="hasta_selected"
                        label="Hasta"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="pt-0 mt-0"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="hasta_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="hasta_selected = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.hasta_selected.save(hasta_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-md-3 pt-0">
                  <v-combobox class="pt-0 mt-0" v-model="warehouse_selected" item-text="name" item-value="id" :items="warehouses" label="Deposito" clearable></v-combobox>
                </div>
                <div class="col-md-3 pt-0">
                  <v-combobox class="pt-0 mt-0" @change="selectedCategory()" v-model="category_selected" item-text="name" item-value="id" :items="categories" label="Rubro" clearable></v-combobox>
                </div>
                <div class="col-md-3 pt-0">
                  <v-combobox class="pt-0 mt-0" v-model="subcategory_selected" item-text="name" item-value="id" :items="subcategories" label="Sub Rubro" clearable></v-combobox>
                </div>
                <div class="col-md-3 pt-0">
                  <v-combobox class="pt-0 mt-0" @change="selectedProduct()" v-model="product_selected" item-text="name" item-value="id" :items="products" label="Producto" clearable></v-combobox>
                </div>
                <div class="col-md-3 pt-0">
                  <v-combobox class="pt-0 mt-0" v-model="product_presentation_selected" item-text="name" item-value="id" :items="presentations" label="Presentación" clearable></v-combobox>
                </div>
                <div class="col-md-3 pt-0">
                  <v-text-field clearable v-model="sku_selected" autocomplete="off" class="pt-0 mt-0" label="SKU"></v-text-field>
                </div>
                <div class="col-md-3 pt-0">
                  <v-text-field clearable v-model="barcode_selected" autocomplete="off" class="pt-0 mt-0" label="Código de barras"></v-text-field>
                </div>
                <div class="col-md-3 pt-0">
                  <v-combobox class="pt-0 mt-0" v-model="action_selected" item-text="name" item-value="id" :items="actions" label="Acción" clearable></v-combobox>
                </div>
                <div class="col-md-3 pt-0">
                  <v-combobox class="pt-0 mt-0" v-model="stock_type_selected" item-text="name" item-value="id" :items="stock_types" label="Tipo de Stock" clearable></v-combobox>
                </div>

                <div class="col-md-3 pt-0" style="display: flex; justify-content: end">
                  <v-btn color="blue-grey" class="mr-2" dark :loading="dialog_export" @click="exportFile()"> Exportar <font-awesome-icon class="ml-2" icon="download" /></v-btn>

                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.creation_date }}</td>
              <td>{{ props.item.warehouse_origin.name }}</td>
              <td>{{ props.item.action.id == "T" ? props.item.warehouse_destination.name : "-" }}</td>
              <td>{{ props.item.product_presentation_label }}</td>
              <td>{{ props.item.presentation_type }}</td>
              <td>{{ props.item.user.business_name }}</td>
              <td>{{ props.item.action.name }}</td>
              <td>{{ props.item.stock_type.name }}</td>
              <td>{{ props.item.stock_updated }}</td>
              <td>{{ props.item.description }}</td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import { historialStockService } from "@/libs/ws/historialStockService";

import GenericMixin from "@/mixins/GenericMixin.vue";
import SettingsMixin from "@/mixins/SettingsMixin.vue";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import ExportMixin from "@/mixins/ExportMixin.vue";
import { formatUtils } from "@/libs/utils/formatUtils";

export default {
  components: { Title },
  mixins: [GenericMixin, RolMixin, ExportMixin, SettingsMixin],
  data() {
    return {
      formatUtils: formatUtils,
      parent: this,
      service: historialStockService,
      page_title: "Historial de Stock",
      mainItem: {
        id: "",
      },
      defaultItem: null,
      headers: [
        {
          text: "Fecha",
          align: "left",
          sortable: true,
          value: "creation_date",
        },
        {
          text: "Depósito Origen",
          align: "left",
          sortable: true,
          value: "warehouse_origin.name",
        },
        {
          text: "Depósito Destino",
          align: "left",
          sortable: true,
          value: "warehouse_destination.name",
        },
        {
          text: "Producto",
          align: "left",
          sortable: true,
          value: "product_presentation.label",
        },
        {
          text: "Tipo de presentacion",
          align: "left",
          sortable: true,
          value: "product_presentation.presentation_type.name",
        },
        {
          text: "Usuario",
          align: "left",
          sortable: true,
          value: "user.business_name",
        },
        {
          text: "Accion",
          align: "left",
          sortable: true,
          value: "action.name",
        },
        {
          text: "Tipo de Stock",
          align: "left",
          sortable: true,
          value: "stock_type.name",
        },
        {
          text: "Stock Modificado",
          align: "left",
          sortable: true,
          value: "stock_updated",
        },
        {
          text: "Observacinoes",
          align: "left",
          sortable: true,
          value: "description",
        },
      ],
      list: [],
      dialog_delete: false,

      warehouses: [],
      categories: [],
      subcategories: [],
      products: [],
      presentations: [],
      actions: [],
      stock_types: [],
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 0).toISOString().slice(0, 10),
      hasta_selected_open: false,
      desde_selected_open: false,
      warehouse_selected: null,
      category_selected: null,
      subcategory_selected: null,
      product_selected: null,
      product_presentation_selected: null,
      sku_selected: null,
      barcode_selected: null,
      action_selected: null,
      stock_type_selected: null,
    };
  },
  mounted() {
    this.loadComboData();
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
    this.loadList(this.service);
  },
  methods: {
    label(item) {
      if (this.isProductCompany()) return item.label;
      return item.name;
    },
    disableDatefilter() {
      return this.sku_selected != null || this.barcode_selected != null;
    },
    loadComboData() {
      //Warehouses
      generalService.setSection(Constant.SECTION_WAREHOUSES);
      generalService
        .filter({ enabled: true })
        .then((response) => {
          this.warehouses = response.result;
          //Auto select default
          this.warehouses.forEach((element) => {
            if (element.default_warehouse) {
              this.warehouse_selected = element;
            }
          });
        })
        .catch((e) => {
          console.log("categories.filter error");
          console.log(e);
        });

      //Categories
      generalService.setSection(Constant.SECTION_CATEGORIES);
      generalService
        .filter()
        .then((response) => {
          this.categories = response.result;
        })
        .catch((e) => {
          console.log("categories.filter error");
          console.log(e);
        });

      //Products
      generalService.setSection(Constant.SECTION_PRODUCTS);
      generalService
        .filter()
        .then((response) => {
          this.products = response.result;
        })
        .catch((e) => {
          console.log("products.filter error");
          console.log(e);
        });

      //Actions
      historialStockService
        .actions()
        .then((response) => {
          this.actions = response.result;
        })
        .catch((e) => {
          console.log("actions.filter error");
          console.log(e);
        });

      //Stock Types
      historialStockService
        .stock_types()
        .then((response) => {
          this.stock_types = response.result;
        })
        .catch((e) => {
          console.log("stock_types.filter error");
          console.log(e);
        });
    },
    selectedCategory() {
      if (this.category_selected) {
        generalService.setSection(Constant.SECTION_SUBCATEGORIES);
        generalService
          .filter({ category: this.category_selected.id })
          .then((response) => {
            this.subcategories = response.result;
          })
          .catch((e) => {
            console.log("subcategories.filter error");
            console.log(e);
          });
      }
    },
    selectedProduct() {
      if (this.product_selected) {
        generalService.setSection(Constant.SECTION_PRESENTATIONS);
        generalService
          .filter({ product: this.product_selected.id })
          .then((response) => {
            this.presentations = response.result;
          })
          .catch((e) => {
            console.log("presentations.filter error");
            console.log(e);
          });
      }
    },
    deleteItemConfirm() {
      this.delete(this.service);
      this.dialog_delete = false;
      this.loadList(this.service);
    },
    getFilterParams() {
      const param = {
        warehouse: this.warehouse_selected,
        category: this.category_selected,
        subcategory: this.subcategory_selected,
        product: this.product_selected,
        product_presentation: this.product_presentation_selected,
        sku: this.sku_selected,
        barcode: this.barcode_selected,
        action: this.action_selected,
        stock_type: this.stock_type_selected,
      };
      return param;
    },
    filter() {
      this.loadList(this.service);
    },
    exportFile() {
      var param = this.getFilterParams();
      param.export = true;
      this.exportExcel(Constant.SECTION_HISTORIAL_STOCKS + "/list", param, "HistorialStock.xlsx");
    },
  },
};
</script>
