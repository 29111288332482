<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" persistent max-width="1200px">
      <v-card class="background-voucher" style="background: rgb(221, 225, 230)">
        <v-form v-on:submit.prevent="createItem">
          <v-card-title class="mb-4">
            <span class="headline">Cobranza</span>
          </v-card-title>
          <v-card-text>
            <div class="row">
              <div class="col-md-12">
                <!--Datos Generales-->
                <DatosGenerales :item="item" :isViewModal="isViewModal()" :open="dialog" :openFromVoucher="openFromVoucher" :validator="$validator" />

                <!--Comprobante a Cobrar-->
                <ComprobanteACobrar :item="item" :isViewModal="isViewModal()" :open="dialog" :voucher="voucher" :validator="$validator" />

                <!--Formas de Cobro-->
                <FormasDeCobro :item="item" :isViewModal="isViewModal()" :open="dialog" :validator="$validator" />

                <!--Retenciones-->
                <Retenciones :item="item" :isViewModal="isViewModal()" :open="dialog" :validator="$validator" />

                <v-row>
                  <!--Info Extra-->
                  <InfoExtra :dialog="dialog" :item="item" :open="dialog" :isViewModal="isViewModal()" :validator="$validator"/>
                  <!--Totales-->
                  <Totales :total="total" :open="dialog" />
                </v-row>
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('closeDialog')">Cerrar</v-btn>
            <v-btn color="success" v-if="!isViewModal()" text type="submit" :loading="loading">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { formatUtils } from "@/libs/utils/formatUtils";
import { chargeService } from "@/libs/ws/chargeService";

import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";

import DatosGenerales from "@/views/Charges/ChargeComponent/DatosGenerales.vue";
import ComprobanteACobrar from "@/views/Charges/ChargeComponent/ComprobanteACobrar.vue";
import FormasDeCobro from "@/views/Charges/ChargeComponent/FormasDeCobro.vue";
import Retenciones from "@/views/Charges/ChargeComponent/Retenciones.vue";
import InfoExtra from "@/components/Comprobantes/InfoExtra.vue";
import Totales from "@/views/Charges/ChargeComponent/Totales.vue";

export default {
  mixins: [ModalGenericMixin],
  components: { DatosGenerales, ComprobanteACobrar, Retenciones, InfoExtra, Totales, FormasDeCobro },
  props: {
    voucher: { type: Object, default: null },
    openFromVoucher: { type: Boolean, default: false },
    charge: { type: Object, default: null },
    dialog: Boolean,
    button_name: String,
    formBtn: String,
    closeDialog: Function,
    loadList: Function,
  },
  data() {
    return {
      show: false,
      formatUtils: formatUtils,
      total: 0,
      item: {
        id: 0,
        client: null,
        vouchers: [],
        payment_method: null,
        cbte_fch: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
        dolar_cotiz: null,
        fch_charge_efective: null,
        tributes: [],
        charges_payment_methods: [],
      },
      defaultItem: null,
    };
  },
  created: function () {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.$emit("closeDialog");
      }
    });
  },
  watch: {
    dialog(val) {
      this.$validator.reset();
      this.errors.clear();
      this.init();
      //cuando le envio el voucher por props desde facturacion
      if (val && this.voucher != null) {
        //this.item.voucher = this.voucher;

        this.item.client = this.voucher.receiver.client;
        //this.item.amount = this.voucher.pending_to_charged;
      }
    },
    item: {
      handler: function (newValue) {
        this.getTotal();
      },
      deep: true,
    },
  },
  mounted() {
    this.defaultItem = JSON.parse(JSON.stringify(this.item));
    this.init();
  },
  methods: {
    init() {
      if (this.charge != null) {
        this.item = this.charge;
      } else {
        this.item = JSON.parse(JSON.stringify(this.defaultItem));
      }
    },
    getTotal() {
      let total = 0;

      this.item.tributes.forEach((tribute) => {
        const amount = tribute.amount != undefined ? tribute.amount : 0;
        total += parseFloat(amount);
      });

      this.item.charges_payment_methods.forEach((c_payment_methos) => {
        const amount = c_payment_methos.amount != undefined ? c_payment_methos.amount : 0;
        total += parseFloat(amount);
      });

      this.total = total;
    },
    customValidations() {
      if (this.item.charges_payment_methods.length == 0 && this.item.tributes.length == 0) {
        this.$toast.error("Debe ingresar al menos una forma de cobro o tributo", "Error");
        return false;
      }

      if (this.item.vouchers.length == 0) {
        this.$toast.error("Debe ingresar al menos un comprobante a cobrar", "Error");
        return false;
      }

      var total_vouchers = 0.0;
      this.item.vouchers.forEach((voucher) => {
        total_vouchers += parseFloat(voucher.imp_total);
      });

      if (this.total.toFixed(2) != total_vouchers.toFixed(2)) {
        this.$toast.error("El total de los comprobantes a cobrar debe ser igual al total de la cobranza", "Error");
        return false;
      }
      return true;
    },
    provide() {
      return {
        $validator: this.$validator,
      };
    },
    createItem(e) {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          e.preventDefault();

          if (!this.customValidations()) {
            return;
          }

          this.loading = true;

          console.log("this.item", this.item);

          const data = {
            vouchers_arr: this.item.vouchers,
            client: this.item.client.id,
            payment_method: this.item.payment_method,
            cbte_fch: this.item.cbte_fch,
            dolar_cotiz: this.item.dolar_cotiz,
            amount: this.total,
            fch_charge_efective: this.item.fch_charge_efective,
            tributes_arr: this.item.tributes,
            charges_payment_methods_arr: this.item.charges_payment_methods,
          };

          chargeService
            .create(data)
            .then((response) => {
              if (response.status && response.result.id > 0) {
                this.$toast.success("La cobranza se creo correctamente", "OK");
                this.$emit("closeDialog");
                this.$emit("loadList");
                this.item = JSON.parse(JSON.stringify(this.defaultItem));
              } else {
                if (Object.prototype.hasOwnProperty.call(response, "message")) {
                  this.$toast.error(response.message, "Error");
                } else {
                  this.$toast.error(response.msg, "Error");
                }
              }
            })
            .catch((response) => {
              this.$toast.error(response.msg, "Error");
            })
            .finally(() => (this.loading = false));
        }
      });
    },
    isViewModal() {
      return this.formBtn == "";
    },
  },
};
</script>
