<template>
    <div>
      <v-dialog v-model="open" max-width="550" persistent>
        <v-card>
          <v-card-title class="headline mb-4">{{ title }}</v-card-title>
  
          <v-card-text>
            <div class="row">
              <div class="col-md-12">
                <DatePickerEsp
                    :initToday="true"
                    label="Fecha estimada de entrega"
                    :date="purchase_order.estimated_delvery_date"
                    :isRequired="true"
                    name="estimated_delvery_date"
                    
                    @setDate="
                    (date) => {
                        purchase_order.estimated_delvery_date = date;
                    }
                    "
                />
               
                  <v-checkbox label="Entregado" v-model="purchase_order.delivered"></v-checkbox>
               
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>  
            <v-btn color="red darken-1" text @click="close()"> Cancelar </v-btn>
            <div >
              <v-btn color="green darken-1" text @click="generatePurchaseOrder" :loading="loading"> {{ "Editar" }} </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
  <script>
  import { purchaseOrderService } from "@/libs/ws/purchaseOrderService";
  
  import { formatUtils } from "@/libs/utils/formatUtils";
  import SettingsMixin from "@/mixins/SettingsMixin.vue";
  import DatePickerEsp from "@/components/DatePickerEsp.vue";


  export default {
    components: { DatePickerEsp  },
    mixins: [SettingsMixin],
    props: {
      title: String,
      checkbox_label: String,
      open: Boolean,
      
      closeDialog: Function,
      reload: Function,
      cancel: Function,
      item: Object,
      updateMainItem: Function,
  
    },
    data: () => ({
      purchaseOrderService: purchaseOrderService,
      loading: false,
      formatUtils: formatUtils,
      purchase_order: {
        id: "",
        estimated_delvery_date: new Date(
          new Date().toLocaleDateString("en-US", {
            timeZone: "America/Buenos_Aires",
          })
        )
          .toISOString()
          .slice(0, 10),
        delivered: false
      },
      defaultPurchaseOrder: null
    }),
    created: function () {
      window.addEventListener("keydown", (e) => {
        if (e.key == "Escape") {
          this.close();
        }
      });
    },
    watch: {
      open: function (val) {
        if (this.item && this.item.id != "") {
          this.purchase_order = JSON.parse(JSON.stringify(this.item));
        }
      },
    },
    mounted() {
      this.defaultPurchaseOrder = JSON.parse(JSON.stringify(this.purchase_order));
    },
    methods: {
      close() {
        this.purchase_order = JSON.parse(JSON.stringify(this.defaultPurchaseOrder));
  
        this.$emit("updateMainItem");
        this.$emit("closeDialog");
      },
      generatePurchaseOrder(event) {
        this.$validator.validate().then((valid) => {
          if (valid) {
            let data = this.getParams();

            this.loading = true;

            if (this.purchase_order && this.purchase_order.id > 0) {
              purchaseOrderService
                .update_delivered(data)
                .then((response) => {
                  if (response.status) {
                    this.$toast.success("La Orden de Compra se editó correctamente", "OK");
  
                    this.purchase_order = JSON.parse(JSON.stringify(this.defaultPurchaseOrder));
                    this.$emit("reload");
                    this.close();
                  } else {
                    if (Object.prototype.hasOwnProperty.call(response, "message")) {
                      this.$toast.error(response.message, "Error");
                    } else {
                      if (Object.prototype.hasOwnProperty.call(response, "message")) {
                        this.$toast.error(response.message, "Error");
                      } else {
                        this.$toast.error(response.msg, "Error");
                      }
                    }
                  }
                })
                .catch((response) => {
                  this.$toast.error(response.msg, "Error");
                })
                .finally(() => {
                  this.loading = false;
                });
            }
          } else {
            this.$toast.error("Complete los campos requeridos", "Error");
          }
        });
      },
      
      getParams() {
        let data = {
          id: this.purchase_order.id,
          delivered: this.purchase_order.delivered,
          estimated_delvery_date: this.purchase_order.estimated_delvery_date,
        }

        return data;
      }
    },
  };
  </script>
  