<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" max-width="750">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <div class="row justify-content-center">
              <div class="col-md-12">
                <v-text-field data-vv-as="Nombre" label="Nombre*" name="name" v-model="item.name" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("name") }}</span>

                <!--<v-textarea outlined v-model="item.description" data-vv-as="description" name="description" label="Descripción" :disabled="isViewModal()"></v-textarea>-->

                <div class="row mt-3">
                  <div class="col-md-6">
                    <v-text-field data-vv-as="Código (SKU)" label="Código (SKU)" name="sku" v-model="item.sku" :disabled="isViewModal()"></v-text-field>
                    <span class="red--text">{{ errors.first("sku") }}</span>
                  </div>
                  <div class="col-md-6">
                    <v-combobox
                      v-model="item.default_iva_id"
                      data-vv-as="IVA"
                      name="default_iva_id"
                      item-text="Desc"
                      item-value="Id"
                      :items="ivas"
                      label="IVA %*"
                      autocomplete="off"
                      v-validate="'required|combobox_required'"
                      :disabled="isViewModal()"
                    ></v-combobox>
                    <span class="red--text">{{ errors.first("default_iva_id") }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <v-combobox
                      v-model="item.type"
                      autocomplete="off"
                      data-vv-as="tipo"
                      name="type"
                      item-text="name"
                      item-value="id"
                      v-validate="'combobox'"
                      :items="product_types"
                      label="Tipo"
                      clearable
                      :disabled="isViewModal()"
                    ></v-combobox>
                    <span class="red--text">{{ errors.first("type") }}</span>
                  </div>
                  <div class="col-md-4">
                    <v-combobox
                      v-model="item.category"
                      data-vv-as="Rubro"
                      name="category"
                      item-text="name"
                      item-value="id"
                      :items="categories"
                      label="Rubro*"
                      autocomplete="off"
                      v-validate="'required|combobox_required'"
                      :disabled="isViewModal()"
                      @change="CategoryComboChange()"
                    ></v-combobox>
                    <span class="red--text">{{ errors.first("category") }}</span>
                  </div>
                  <div class="col-md-4">
                    <v-combobox
                      v-model="item.subcategory"
                      data-vv-as="Subrubro"
                      name="subcategory"
                      item-text="name"
                      item-value="id"
                      :items="subcategories"
                      label="Subrubro"
                      autocomplete="off"
                      v-validate="'combobox'"
                      :disabled="isViewModal()"
                    ></v-combobox>
                    <span class="red--text">{{ errors.first("subcategory") }}</span>
                  </div>
                </div>
                <div class="row" v-if="item.presentations.length > 0">
                  <div class="col">
                    <v-text-field
                      filled
                      prepend-icon="attach_money"
                      autocomplete="off"
                      name="costo"
                      data-vv-as="Costo interno s/IVA"
                      label="Costo interno s/IVA"
                      type="text"
                      :disabled="isViewModal()"
                      v-model="item.presentations[0].cost"
                    />
                    <span class="red--text">{{ errors.first("costo") }}</span>
                  </div>
                  <div class="col">
                    <v-text-field
                      filled
                      prepend-icon="attach_money"
                      autocomplete="off"
                      name="precio_unitario"
                      data-vv-as="Precio unitario s/IVA"
                      label="Precio unitario s/IVA"
                      type="text"
                      :disabled="isViewModal()"
                      v-model="item.presentations[0].base_price"
                    />
                    <span class="red--text">{{ errors.first("precio_unitario") }}</span>
                  </div>
                </div>

                <v-checkbox label="Habilitado" :disabled="isViewModal()" class="mb-5 mt-2" v-model="item.enabled"></v-checkbox>
              </div>
            </div>
          </v-card-text>

          <v-card-actions style="position: absolute; bottom: 0; width: 100%">
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" v-if="!isViewModal()" :loading="loading">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { generalService } from "@/libs/ws/generalService";
import { spentService } from "@/libs/ws/spentService";
import ConstantSections from "@/constants/sections";
import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";
export default {
  mixins: [ModalGenericMixin],
  components: {},
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      show: false,
      categories: [],
      subcategories: [],
      ivas: [],
      e1: 1,
    };
  },
  watch: {
    item() {
      //console.log(this.item, "cambio item");
      //this.$emit("updateItem", this.item);
    },
    dialog() {
      if (this.dialog && this.item != null && this.item.presentations.length == 0) {
        this.item.presentations.push({
          name: "",
          cost: 0,
          base_price: 0,
          sellable: true,
        });
      }
      this.errors.clear();
      this.e1 = 1;
    },
  },
  mounted() {
    this.loadComboData();
  },
  methods: {
    next() {
      this.$validator.validate().then((isValid) => {
        if (!isValid) {
          //do whatever you need to do when something failed validation
        } else {
          this.e1 = 2;
        }
      });
    },
    isViewModal() {
      return this.formBtn == "";
    },
    CategoryComboChange(v) {
      generalService.filter({ category: this.item.category.id }, "subcategories").then((response) => {
        this.subcategories = response.result;
      });
    },
    loadComboData() {
      this.ivas = spentService.ivas();
      generalService.filter({}, ConstantSections.SECTION_CATEGORIES).then((response) => {
        this.categories = response.result;
      });
    },
    createItem(e) {
      if (this.loading) return;

      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();

          this.loading = true;

          let data = JSON.parse(JSON.stringify(this.item));
          data.presentations[0].name = data.name;

          data["presentations_arr"] = data.presentations;
          delete data.presentations;

          if (this.item.id > 0) {
            this.service
              .update(data)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          } else {
            this.service
              .create(data)
              .then((response) => {
                if (response.status && response.result.id > 0) {
                  this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                console.log(response);
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          }
        }
      });
    },
  },
};
</script>
