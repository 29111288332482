<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <v-stepper v-model="step">
              <v-stepper-header style="background: #607d8b; color: white">
                <v-stepper-step :complete="step > 1" step="1"> <span class="text-white">Informacion Basica</span> </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="step > 2" step="2"> <span class="text-white">Comprobantes</span> </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3"> <span class="text-white">Adicionales</span> </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1" style="min-height: 50vh">
                  <v-card class="elevation-0" style="min-height: 380px">
                    <!-- START FORM DIALOG -->
                    <div class="row justify-content-center align-items-center">
                      <div class="col-md-6">
                        <p class="fs-16 text-subtitle-1" style="color: #546e7a">Seleccione el transporte y la zona del viaje</p>

                        <DatePickerEsp
                          :initToday="true"
                          label="Fecha"
                          :date="item.date"
                          :isRequired="true"
                          name="date"
                          :isViewModal="isViewModal()"
                          @setDate="
                            (date) => {
                              item.date = date;
                            }
                          "
                        />

                        <v-combobox
                          v-model="item.carrier"
                          data-vv-as="transporte"
                          name="transporte"
                          item-text="name"
                          item-value="id"
                          :items="carriers"
                          label="Transporte*"
                          autocomplete="off"
                          v-validate="'required|combobox_required'"
                          :disabled="isViewModal()"
                          clearable
                        ></v-combobox>
                        <span class="red--text">{{ errors.first("transporte") }}</span>

                        <v-combobox
                          v-model="item.zone"
                          data-vv-as="zona"
                          name="zona"
                          item-text="name"
                          item-value="id"
                          :items="zones"
                          label="Zona"
                          autocomplete="off"
                          v-validate="'combobox'"
                          :disabled="isViewModal()"
                          clearable
                        ></v-combobox>
                        <span class="red--text">{{ errors.first("zona") }}</span>
                      </div>
                    </div>
                  </v-card>

                  <v-btn color="primary" @click="nextStep(2)" small> Siguiente </v-btn>
                </v-stepper-content>

                <v-stepper-content step="2" style="min-height: 50vh">
                  <div style="min-height: 380px">
                    <v-row class="mb-3">
                      <v-col class="d-flex align-items-end">
                        <v-btn color="primary" class="mb-3" @click="dialog_add_voucher = true" small> Agregar Comprobante </v-btn>
                      </v-col>

                      <v-col>
                        <v-text-field
                          hide-details
                          dense
                          filled
                          rounded
                          data-vv-as="Pallets"
                          label="Cantidad de pallets"
                          name="pallet_amount"
                          v-model="item.pallet_amount"
                          v-validate="'required|numeric|min_value:0'"
                          :disabled="isViewModal()"
                          :loading="loading_calculate"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <span class="red--text">{{ errors.first("pallet_amount") }}</span>
                    <v-data-table
                      :headers="headersVouchers"
                      :items="vouchers"
                      loading-text="Cargando..."
                      :options="{
                        descending: true,
                        itemsPerPage: 15,
                      }"
                      @pagination="pagination = $event"
                      :footer-props="{
                        itemsPerPageOptions: [5, 10, 15, 50, -1],
                      }"
                    >
                      <template v-slot:item="props">
                        <tr>
                          <td>{{ props.item.cbte_fch }}</td>
                          <td>{{ props.item.cbte_tipo_name }}</td>
                          <td>{{ props.item.voucher_number != null ? props.item.pto_vta.toString().padStart(4, "0") + "-" + props.item.voucher_number.toString().padStart(8, "0") : "-" }}</td>
                          <td>{{ props.item.emitter.name }}</td>
                          <td>{{ props.item.receiver.name }}</td>
                          <td>{{ props.item.client_shipping_address != null ? props.item.client_shipping_address.name : props.item.shipping_address }}</td>

                          <td class="text-right">{{ formatUtils.formatPrice(props.item.total_ars) }}</td>
                          <td class="text-center px-0" style="min-width: 160px">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-icon @click="removeItem(props.item)" v-on="on" small color="red" class="mr-2"> indeterminate_check_box </v-icon>
                              </template>
                              <span>Eliminar</span>
                            </v-tooltip>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </div>
                  <v-btn color="primary" @click="nextStep(3)" small> Siguiente </v-btn>
                  <v-btn text small @click="step--" class="ml-2"> Volver </v-btn>
                </v-stepper-content>

                <v-stepper-content step="3" style="min-height: 50vh">
                  <v-row class="mx-0 mt-1 mb-3" style="border: 1px rgb(180, 176, 176) solid">
                    <v-col md="6">
                      <v-text-field v-model="item_additional.name" data-vv-as="Nombre" name="name" label="Nombre" v-validate="''" :disabled="isViewModal()"></v-text-field>
                      <span class="red--text">{{ errors.first("name") }}</span>
                    </v-col>
                    <v-col md="3">
                      <v-text-field v-model="item_additional.price" data-vv-as="Precio" name="price" label="Precio" v-validate="'numeric|min_value:0'" :disabled="isViewModal()"> </v-text-field>
                      <span class="red--text">{{ errors.first("price") }}</span>
                    </v-col>
                    <v-col md="3" class="justify-content-center d-flex">
                      <v-btn class="mt-4" color="primary" @click="addAdditional" small> Agregar </v-btn>
                    </v-col>
                  </v-row>
                  <v-data-table
                    :headers="headersAdditional"
                    :items="additionals"
                    loading-text="Cargando..."
                    :options="{
                      descending: true,
                      itemsPerPage: 15,
                    }"
                    @pagination="pagination = $event"
                    :footer-props="{
                      itemsPerPageOptions: [5, 10, 15, 50, -1],
                    }"
                  >
                    <template v-slot:item="props">
                      <tr>
                        <td>{{ props.item.name }}</td>
                        <td class="text-right">{{ formatUtils.formatPrice(props.item.price) }}</td>
                        <td class="text-center px-0" style="min-width: 160px">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-icon @click="removeItemAdditional(props.item)" v-on="on" small color="red" class="mr-2"> indeterminate_check_box </v-icon>
                            </template>
                            <span>Eliminar</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>

                  <v-btn text small @click="step--"> Volver </v-btn>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" v-if="!isViewModal()" :disabled="step != 3" :loading="loading">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <ModalAddVoucher :show_dialog="dialog_add_voucher" @updateDialog="dialog_add_voucher = false" @addSelected="addSelected" :carrier="item.carrier" :validator="$validator" />
  </v-layout>
</template>

<script>
import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import ModalAddVoucher from "./ModalAddVoucher.vue";
import { formatUtils } from "@/libs/utils/formatUtils";
import DatePickerEsp from "@/components/DatePickerEsp.vue";

export default {
  mixins: [ModalGenericMixin],
  components: { ModalAddVoucher, DatePickerEsp },
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      formatUtils: formatUtils,
      show: false,
      carriers: [],
      zones: [],
      step: 1,
      vouchers: [],
      additionals: [],
      dialog_add_voucher: false,
      item_additional: {
        name: "",
        price: "",
      },
      pagination: {
        page: 1,
        itemsPerPage: 5,
      },
      headersAdditional: [
        { text: "Nombre", align: "left", sortable: true, value: "name" },
        { text: "Precio", align: "right", sortable: true, value: "price" },
        { text: "Accion", value: "", align: "center" },
      ],
      headersVouchers: [
        { text: "Fecha", align: "left", sortable: true, value: "cbte_fch" },
        { text: "Comprobante", align: "left", sortable: true, value: "cbte_tipo_name" },
        { text: "Nº Comprobante", align: "left", sortable: true, value: "voucher_number" },
        { text: "Emisor", align: "left", sortable: true, value: "emitter.name" },
        { text: "Receptor", align: "left", sortable: true, value: "receiver.name" },
        { text: "Pto. de entrega", align: "left", sortable: true, value: "shipping_address" },
        { text: "Total ARS", align: "center", sortable: true, value: "imp_total" },
        { text: "Accion", value: "", align: "center" },
      ],
      loading_calculate: false,
    };
  },
  watch: {
    item: {
      handler: function (newValue) {
        this.$emit("updateItem", newValue);
      },
      deep: true,
    },
    vouchers: {
      handler: function (newValue) {
        this.calculatePallets();
      },
      deep: true,
    },
    dialog() {
      if (this.item.id > 0) {
        this.vouchers = this.item.vouchers;
        this.additionals = this.item.additionals;
      } else {
        this.vouchers = [];
        this.additionals = [];
      }
      this.errors.clear();
      this.step = 1;
    },
  },
  mounted() {
    this.loadCombobox();
  },
  methods: {
    calculatePallets() {
      this.loading_calculate = true;
      generalService.setSection(Constant.SECTION_SHIPPING);

      generalService
        .query({ vouchers: this.vouchers }, Constant.SECTION_SHIPPING + "/calculate-pallet")
        .then((response) => {
          this.item.pallet_amount = response.result;
        })
        .catch((response) => {
          this.$toast.error(response.msg, "Error");
        })
        .finally(() => (this.loading_calculate = false));
    },
    async addAdditional() {
      let valid1 = await this.$validator.validate("name", this.item_additional.name);
      let valid2 = await this.$validator.validate("price", this.item_additional.price);
      if (valid1 && valid2) {
        this.$validator.reset();
        this.additionals.push({ ...this.item_additional });
        this.item_additional = { name: "", price: "" };
      }
    },
    async nextStep(nextStep) {
      if (nextStep == 2) {
        let valid1 = await this.$validator.validate("transporte", this.item.carrier);
        let valid2 = true; //await this.$validator.validate("zona", this.item.zone);
        if (valid1 && valid2) {
          this.step = nextStep;
        }
      }
      if (nextStep == 3) {
        let valid1 = await this.$validator.validate("pallet_amount", this.item.pallet_amount);
        if (valid1) {
          this.item.vouchers = [...this.vouchers];
          this.step = nextStep;
        }
      }
    },
    addSelected(selecteds) {
      const existingVouchers = this.vouchers || [];
      const newVouchers = selecteds.filter((selected) => !existingVouchers.some((existing) => existing.id === selected.id));
      this.vouchers = existingVouchers.concat(newVouchers);
      this.dialog_add_voucher = false;
    },
    removeItem(item) {
      const index_item = this.vouchers.indexOf(item);
      if (index_item > -1) this.vouchers.splice(index_item, 1);
    },
    removeItemAdditional(item) {
      const index_item = this.additionals.indexOf(item);
      if (index_item > -1) this.additionals.splice(index_item, 1);
    },
    loadCombobox() {
      generalService.setSection(Constant.SECTION_CARRIER);

      generalService
        .filter({ enabled: true })
        .then((response) => {
          this.carriers = response.result;
        })
        .catch((response) => {
          this.$toast.error(response.msg, "Error");
        });

      generalService.setSection(Constant.SECTION_ZONE);
      generalService
        .filter({ enabled: true })
        .then((response) => {
          this.zones = response.result;
        })
        .catch((response) => {
          this.$toast.error(response.msg, "Error");
        });
    },
    isViewModal() {
      return this.formBtn == "";
    },
    createItem(e) {
      if (this.loading) return;

      this.service.setSection(Constant.SECTION_SHIPPING);

      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();

          this.loading = true;

          //voucher se setea en el nextStep
          this.item.additionals = [...this.additionals];
          const data = this.item;

          data["vouchers_arr"] = this.item.vouchers;
          data["additionals_arr"] = this.item.additionals;

          delete data["vouchers"];
          delete data["additionals"];

          if (this.item.id > 0) {
            this.service
              .update(data)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          } else {
            this.service
              .create(data)
              .then((response) => {
                console.log(response);
                if (response.status) {
                  this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                console.log(response);
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          }
        }
      });
    },
  },
};
</script>
