<template>
  <v-dialog v-model="dialog" @click:outside="$emit('updateDialog')" width="750">
    <v-card>
      <v-card-title>
        <span class="headline">Presentación</span>
      </v-card-title>

      <v-card-text>
        <v-text-field autocomplete="off" name="name" label="Nombre*" type="text" v-model="item.name" v-validate="'required'" />
        <span class="red--text">{{ errors.first("name") }}</span>

        <div class="row">
          <div class="col">
            <v-text-field autocomplete="off" name="barcode" label="Codigo de barras" type="text" v-model="item.barcode" />
            <span class="red--text">{{ errors.first("barcode") }}</span>
          </div>
          <div class="col">
            <v-combobox
              v-model="item.presentation_type"
              data-vv-as="Tipo de presentacion"
              name="presentation_type"
              item-text="name"
              item-value="id"
              :items="presentation_types"
              label="Tipo de presentacion*"
              autocomplete="off"
              v-validate="'required|combobox_required'"
            ></v-combobox>
            <span class="red--text">{{ errors.first("presentation_type") }}</span>
          </div>
        </div>

        <div class="row mt-0" v-if="getPresentations(item).length > 0">
          <div class="col">
            <v-combobox
              v-if="getPresentations(item).length > 0"
              v-model="item.composite_presentation"
              data-vv-as="Composición de otra presentacion"
              name="composite_presentation"
              item-text="name"
              item-value="id"
              v-validate="'combobox'"
              :items="getPresentations(item)"
              @change="
                () => {
                  item.amount_composite_presentation = null;
                  item.display_stock = item.composite_presentation == null ? true : false;
                }
              "
              label="Composición de otra presentacion"
              autocomplete="off"
            ></v-combobox>
            <span class="red--text">{{ errors.first("composite_presentation") }}</span>
          </div>
          <div class="col">
            <v-text-field
              v-if="getPresentations(item).length > 0"
              :disabled="item.composite_presentation == null"
              autocomplete="off"
              data-vv-as="Cantidad de la composición"
              name="amount_composite_presentation"
              label="Cantidad de la composición"
              v-validate="'decimal'"
              type="number"
              v-model="item.amount_composite_presentation"
            />
            <span class="red--text">{{ errors.first("amount_composite_presentation") }}</span>
          </div>
        </div>
        <v-combobox
          v-model="item.storage_unit"
          data-vv-as="Unidad de almacenamiento"
          name="storage_unit"
          item-text="name"
          item-value="id"
          :items="storage_units"
          label="Unidad de almacenamiento*"
          autocomplete="off"
          v-validate="'required|combobox_required'"
        ></v-combobox>
        <span class="red--text">{{ errors.first("storage_unit") }}</span>

        <div class="row">
          <div class="col">
            <v-combobox
              v-model="item.weight_unit"
              data-vv-as="Unidad de peso"
              name="weight_unit"
              item-text="name"
              item-value="id"
              :items="weight_units"
              label="Unidad de peso"
              v-validate="'combobox'"
              autocomplete="off"
            ></v-combobox>
            <span class="red--text">{{ errors.first("weight_unit") }}</span>
          </div>
          <div class="col">
            <v-text-field autocomplete="off" name="weight" label="Peso" v-validate="'decimal'" type="number" v-model="item.weight" />
            <span class="red--text">{{ errors.first("weight") }}</span>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <v-combobox
              v-model="item.measure_unit"
              data-vv-as="Unidad de medida"
              name="measure_unit"
              item-text="name"
              item-value="id"
              :items="measure_units"
              v-validate="'combobox'"
              label="Unidad de medida"
              autocomplete="off"
            ></v-combobox>
            <span class="red--text">{{ errors.first("measure_unit") }}</span>
          </div>
          <div class="col">
            <v-text-field autocomplete="off" name="heigth" label="Alto" v-validate="'decimal'" type="number" v-model="item.heigth" />
            <span class="red--text">{{ errors.first("heigth") }}</span>
          </div>
          <div class="col">
            <v-text-field autocomplete="off" name="width" label="Ancho" v-validate="'decimal'" type="number" v-model="item.width" />
            <span class="red--text">{{ errors.first("width") }}</span>
          </div>
          <div class="col">
            <v-text-field autocomplete="off" name="length" label="Largo" v-validate="'decimal'" type="number" v-model="item.length" />
            <span class="red--text">{{ errors.first("length") }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <v-text-field filled prepend-icon="attach_money" autocomplete="off" name="costo" data-vv-as="Costo interno s/IVA" label="Costo interno s/IVA" type="text" v-model="item.cost" />
            <span class="red--text">{{ errors.first("costo") }}</span>
          </div>
          <div class="col">
            <v-text-field
              filled
              prepend-icon="attach_money"
              autocomplete="off"
              name="precio_unitario"
              data-vv-as="Precio unitario s/IVA"
              label="Precio unitario s/IVA"
              type="text"
              v-model="item.base_price"
            />
            <span class="red--text">{{ errors.first("precio_unitario") }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <v-checkbox label="Visible en modulo de ventas" v-model="item.sellable"></v-checkbox>
          </div>
          <div class="col">
            <v-checkbox label="Visible en modulo de compras" v-model="item.buyable"></v-checkbox>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="$emit('updateDialog')"> Cancelar </v-btn>
        <v-btn color="green" text @click="addNewPresentation()"> {{ itemEdit != null ? "Editar" : "Agregar" }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Constant from "@/constants/sections";
import { formatUtils } from "@/libs/utils/formatUtils";
import { generalService } from "@/libs/ws/generalService";
import { spentService } from "@/libs/ws/spentService";
export default {
  created() {},
  components: {},
  props: {
    dialog: Boolean,
    product: Object,
    updateDialog: Function,
    addPresentation: Function,
    itemEdit: Object,
  },
  data: () => ({
    item: {
      name: "",
      barcode: "",
      presentation_type: null,
      composite_presentation: null,
      storage_unit: null,
      weight_unit: null,
      weight: "",
      measure_unit: null,
      heigth: "",
      width: "",
      length: "",
      base_price: 0,
      cost: 0,
      sellable: true,
      buyable: false,
    },
    defaultItem: null,
    presentations: [],
    weight_units: [],
    measure_units: [],
    storage_units: [],
    presentation_types: [],
  }),
  watch: {
    dialog() {
      if (this.itemEdit == null) {
        this.item = JSON.parse(JSON.stringify(this.defaultItem));
      } else {
        this.item = JSON.parse(JSON.stringify(this.itemEdit));
      }

      this.errors.clear();
      this.$validator.reset();
    },
  },
  mounted() {
    this.loadComboData();
    this.defaultItem = JSON.parse(JSON.stringify(this.item));
  },
  methods: {
    getPresentations(item) {
      let presentations_aux = this.product.presentations.filter(function (x) {
        return x.name != item.name;
      });
      return this.presentations.concat(presentations_aux);
    },
    loadComboData() {
      generalService.query({}, Constant.SECTION_UNITS + "/list_measure_units").then((response) => {
        this.measure_units = response.result;
      });
      generalService.query({}, Constant.SECTION_UNITS + "/list_weight_units").then((response) => {
        this.weight_units = response.result;
      });
      generalService.query({}, Constant.SECTION_UNITS + "/list_storage_units").then((response) => {
        this.storage_units = response.result;
      });
      generalService.query({}, Constant.SECTION_UNITS + "/list_presentation_types").then((response) => {
        this.presentation_types = response.result;
      });

      if (this.product.id > 0)
        generalService.query({ product: this.product.id }, Constant.SECTION_PRODUCTS + "/presentations").then((response) => {
          this.presentations = response.result;
        });
    },
    addNewPresentation() {
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.$emit("addPresentation", this.item);
        }
      });
    },
  },
};
</script>
