<template>
  <div>
    <v-dialog v-model="open" :max-width="900">
      <v-card class="background-voucher" style="background: rgb(221, 225, 230)">
        <v-card-title class="headline">Asociar Comprobante</v-card-title>

        <v-card-text class="mt-4">
          <ComprobanteAsociadoVoucher
            v-if="!isSpent"
            :invoice="invoice"
            :invoice_to_cancel="null"
            :loading_combos="{}"
            :comprobante_asociado="comprobante_asociado"
            :loading_voucher_asociado="loading_voucher_asociado"
            :error_search_cbte_asociado="error_search_cbte_asociado"
            @searchCbteAsociado="searchCbteAsociadoVoucher"
            :validator="$validator"
          />

          <ComprobanteAsociadoSpent
            v-if="isSpent"
            :invoice="invoice"
            :invoice_to_cancel="null"
            :loading_combos="{}"
            :comprobante_asociado="comprobante_asociado"
            :loading_voucher_asociado="loading_voucher_asociado"
            :error_search_cbte_asociado="error_search_cbte_asociado"
            @searchCbteAsociado="searchCbteAsociadoSpent"
            :validator="$validator"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="$emit('cancel')"> Cancelar </v-btn>

          <v-btn :disabled="invoice == null || invoice.cbte_asoc == null" color="green darken-1" text @click="asociar()" :loading="loading"> Asociar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import ComprobanteAsociadoVoucher from "@/views/Billing/Vouchers/VoucherComponent/ComprobanteAsociado.vue";
import ComprobanteAsociadoSpent from "@/views/Spents/SpentComponent/ComprobanteAsociado.vue";

export default {
  components: { ComprobanteAsociadoVoucher, ComprobanteAsociadoSpent },
  props: {
    open: Boolean,
    cancel: { type: Function, default: null },
    reload: { type: Function, default: null },
    invoice: Object,
    associateVoucher: { type: Function, default: null },
    service: { type: Object, default: null },
    isSpent: { type: Boolean, default: false },
  },
  data: () => ({
    comprobante_asociado: {
      id: null,
      voucher_number: null,
      pto_vta: null,
      cbte_tipo: null,
      anulacion: null,
    },
    invoice_original_id: null,
    comprobante_asociado_default: null,
    loading_voucher_asociado: false,
    error_search_cbte_asociado: false,
    loading: false,
  }),
  watch: {
    open(val) {
      if (val) {
        this.comprobante_asociado = { ...this.comprobante_asociado_default };
        setTimeout(() => {
          // Cambiamos el id para que no quede el componente deshabilitado y lo hago un segundo despues para que detecte el cambio comproabantes
          // asociados y cargue los tipos de comprobantes
          if (this.invoice.id > 0) {
            this.invoice_original_id = this.invoice.id;
          }
          this.invoice.id = 0;
        }, 1000);
      }
    },
  },
  methods: {
    asociar() {
      if (this.invoice.cbte_asoc == null) {
        this.$toast.error("Debe seleccionar un comprobante a asociar");
        return;
      }
      this.loading = true;
      this.service
        .associate(this.invoice_original_id, this.invoice.cbte_asoc.id)
        .then((response) => {
          if (response.status) {
            this.$toast.success("Comprobante asociado correctamente");
            this.$emit("reload");
            this.$emit("associateVoucher", [response.result]);
            this.$emit("cancel");
          } else {
            this.$toast.error(response.msg);
          }
        })
        .catch((e) => {
          this.$toast.error("Error al asociar el comprobante");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    searchCbteAsociadoVoucher() {
      this.invoice.cbte_asoc = null; //Reset Asoc
      this.error_search_cbte_asociado = false;

      if (this.comprobante_asociado.voucher_number != null && this.comprobante_asociado.pto_vta != null && this.comprobante_asociado.cbte_tipo != null && this.comprobante_asociado.cbte_tipo.Id > 0) {
        if (!this.loading_voucher_asociado) {
          this.loading_voucher_asociado = true;

          this.service
            .getVoucherAsoc(this.invoice.emitter.id, this.comprobante_asociado.voucher_number, this.comprobante_asociado.pto_vta, this.comprobante_asociado.cbte_tipo.Id, this.invoice.receiver.id)
            .then((response) => {
              if (response.status) {
                this.invoice.cbte_asoc = response.result;
              } else {
                this.error_search_cbte_asociado = true;
              }
            })
            .finally(() => {
              this.loading_voucher_asociado = false;
            });
        }
      }
    },
    searchCbteAsociadoSpent() {
      this.invoice.cbte_asoc = null; //Reset Asoc
      this.error_search_cbte_asociado = false;

      if (this.comprobante_asociado.voucher_number != null && this.comprobante_asociado.cbte_tipo != null && this.comprobante_asociado.cbte_tipo.Id > 0) {
        if (!this.loading_voucher_asociado) {
          this.loading_voucher_asociado = true;

          this.service
            .getSpentAsoc(this.invoice.supplier.id, this.comprobante_asociado.voucher_number, this.comprobante_asociado.cbte_tipo.Id, this.invoice.receiver.id)
            .then((response) => {
              if (response.status) {
                this.invoice.cbte_asoc = response.result;
              } else {
                this.error_search_cbte_asociado = true;
              }
            })
            .finally(() => {
              this.loading_voucher_asociado = false;
            });
        }
      }
    },
  },
};
</script>
