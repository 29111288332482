<template>
  <v-dialog v-bind:value="dialog" @click:outside="$emit('close')" v-on:input="$emit('close')" max-width="1000px">
    <v-card>
      <v-form v-on:submit.prevent="importar($event)">
        <v-card-title>
          <span class="headline">{{ modal_title }}</span>
        </v-card-title>

        <v-card-text style="min-height: 450px">
          <v-stepper v-model="step" class="elevation-0">
            <v-stepper-header style="background: #607d8b; color: white">
              <v-stepper-step :complete="step > 1" step="1"> <span class="text-white">Paso 1</span> </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="step > 2" step="2"> <span class="text-white">Paso 2</span> </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <v-stepper-content step="1">
                <div class="row justify-content-center">
                  <div class="col-md-5 pt-5">
                    <p class="fs-16 mb-0">Seleccione el tipo de importacion que va a realizar</p>

                    <v-radio-group v-model="radioGroup">
                      <v-radio label="Cheques Emitidos" value="emitidos"></v-radio>
                      <v-radio label="Cheques Recibidos" value="recibidos"></v-radio>
                    </v-radio-group>

                    <v-combobox
                      class="mt-5"
                      v-model="money_account"
                      data-vv-as="Cuenta monetaria"
                      name="money_account"
                      item-text="name"
                      item-value="id"
                      :items="money_accounts"
                      label="Cuenta bancaria desde donde exporto los cheques*"
                      autocomplete="off"
                      v-validate="'required|combobox_required'"
                    ></v-combobox>
                    <span class="red--text">{{ errors.first("money_account") }}</span>

                    <v-file-input accept=".xlsx" label="Seleccionar excel" v-model="excel" hint="Solo admite archivos formato .xlsx (No admite formato .xls)" persistent-hint></v-file-input>

                    <v-btn color="primary" class="mt-3 float-right" small @click="readExcel" :disabled="excel == null || money_account == null">Siguiente</v-btn>
                  </div>
                </div>
              </v-stepper-content>

              <v-stepper-content step="2">
                <div style="border: solid 1px #999">
                  <v-data-table
                    :headers="headers"
                    :items="list_bulk"
                    :search="search"
                    :loading="loading"
                    :options="pagination"
                    loading-text="Cargando... Por favor espere"
                    @pagination="updatePagination"
                  >
                    <template v-slot:item="props">
                      <tr>
                        <td>{{ props.item.object.is_own ? props.item.object.emitter.name : props.item.object.emitter_name }}</td>
                        <td>{{ props.item.object.is_own ? props.item.object.money_account.name : props.item.object.bank.name }}</td>
                        <td>{{ props.item.object.emission_date }}</td>
                        <td>{{ props.item.object.payment_date }}</td>
                        <td>{{ props.item.object.number }}</td>
                        <td>{{ formatUtils.formatPrice(props.item.object.amount) }}</td>
                        <td v-if="!props.item.loading && props.item.status == 1 && props.item.msg == '' && !props.item.status_create">
                          <i class="fa fa-check text-green"></i>
                        </td>
                        <td v-if="!props.item.loading && props.item.status == 1 && props.item.msg != '' && !props.item.status_create">
                          <span class="text-red">{{ props.item.msg }}</span>
                        </td>
                        <td v-if="!props.item.loading && props.item.status == 1 && props.item.status_create">
                          <span class="text-green">Importado</span>
                        </td>

                        <td v-if="!props.item.loading && props.item.status == 0">
                          {{ "Pendiente de procesado" }}
                        </td>
                        <td v-if="props.item.loading">Procesando...</td>
                      </tr>
                    </template>
                    <template v-slot:no-results>
                      <v-alert :value="true" color="error" icon="warning">
                        {{ "Busqueda sin resultados - " + search }}
                      </v-alert>
                    </template>
                  </v-data-table>
                </div>
                <v-btn small class="text-white mt-3 mb-2" @click="step = step - 1" v-if="step != 1" color="red"> Atras </v-btn>

                <div class="pt-0 mt-3">
                  <p style="color: #1976d2; float: left; margin-right: 30px; font-size: 16px; margin-bottom: 5px">Movimientos pendientes de procesar: {{ getPendienteProcesar() }}</p>
                  <p class="text-green" style="float: left; margin-right: 30px; font-size: 16px; margin-bottom: 5px">Movimientos procesados validos: {{ getProcesadosValidos() }}</p>
                  <p style="color: red; margin-bottom: 0px; font-size: 16px; margin-bottom: 5px">Movimientos procesados invalidos: {{ getProcesadosNoValidos() }}</p>
                </div>
                <div class="row mt-1" v-if="loading_create">
                  <div class="col-md-12">
                    <v-progress-linear v-model="percent" color="blue-grey" style="height: 25px !important">
                      <strong>{{ Math.ceil(percent) }}%</strong>
                    </v-progress-linear>
                  </div>
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
          <!-- START FORM DIALOG -->
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="$emit('close')">Cerrar</v-btn>
          <v-btn v-if="formBtn != ''" text color="green" type="submit" :loading="loading_create" :disabled="list_bulk.length == 0"> Importar </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatUtils } from "@/libs/utils/formatUtils";
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import readXlsxFile from "read-excel-file";
import { checkService } from "@/libs/ws/checkService";
export default {
  props: {
    modal_title: String,
    dialog: Boolean,
    mainItem: Object,
    service: Object,
    viewdIndex: Number,
    formBtn: String,
    open: Function,
    close: Function,
    filter: Function,
    create: Function,
  },
  components: {},
  data() {
    return {
      formatUtils: formatUtils,
      step: 1,
      object: null,
      list_bulk: [],
      money_accounts: [],
      radioGroup: null,
      headers: [
        {
          text: "Emisor",
          align: "left",
          sortable: true,
          value: "emitter.name",
        },
        {
          text: "Banco/Cuenta Monetaria",
          align: "left",
          sortable: true,
          value: "money_account.name",
        },
        {
          text: "Fecha de Emision",
          align: "left",
          sortable: true,
          value: "emission_date",
        },
        {
          text: "Fecha de Cobro/Pago",
          align: "left",
          sortable: true,
          value: "payment_date",
        },
        {
          text: "Nro Cheque",
          align: "left",
          sortable: true,
          value: "number",
        },
        {
          text: "Importe",
          align: "right",
          sortable: true,
          value: "amount",
        },
        { text: "Resultado", value: "msg" },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 15,
      },
      search: "",
      loading: false,
      excel: null,
      envios_no_validos: 0,
      envios_creados: 0,
      money_account: null,
      loading_create: false,
      banks: [],
    };
  },
  computed: {
    percent: {
      get() {
        return ((this.getProcesadosValidos() + this.getProcesadosNoValidos()) * 100) / this.list_bulk.length;
      },
      set(value) {
        return value;
      },
    },
  },
  watch: {
    dialog() {
      this.errors.clear();
      this.$validator.reset();
      this.excel = null;
      this.money_account = null;
      this.loading_create = false;
      this.list_bulk = [];
      this.step = 1;
    },
  },
  mounted() {
    this.loadComboData();
    this.loadBanks();
  },
  methods: {
    loadBanks() {
      generalService.setSection(Constant.SECTION_BANKS);

      generalService
        .filter({ has_bank: true })
        .then((response) => {
          this.banks = response.result;
          this.loading = false;
        })
        .catch((e) => {
          console.log("banks.filter error");
          console.log(e);
          this.loading = false;
        });
    },
    getPendienteProcesar() {
      if (this.list_bulk.length == 0) {
        return 0;
      }
      return this.list_bulk.filter((element) => element.status === 0).length;
    },
    getProcesadosValidos() {
      if (this.list_bulk.length == 0) {
        return 0;
      }
      return this.list_bulk.filter((element) => element.status === 1 && element.status_create).length;
    },
    getProcesadosNoValidos() {
      if (this.list_bulk.length == 0) {
        return 0;
      }
      return this.list_bulk.filter((element) => element.status === 1 && !element.status_create).length;
    },
    loadComboData() {
      generalService.setSection(Constant.SECTION_MONEY_ACCOUNTS);
      generalService
        .filter({ has_bank: true })
        .then((response) => {
          this.money_accounts = response.result;
          this.loading = false;
        })
        .catch((e) => {
          console.log("money_accounts.filter error");
          console.log(e);
          this.loading = false;
        });
    },
    parseObject(element) {
      var object = Object.assign({}, this.mainItem);

      object.is_own = this.radioGroup == "emitidos" ? true : false;
      object.number = checkService.getNumber(this.money_account.bank, element, object.is_own);
      object.amount = checkService.getAmount(this.money_account.bank, element, object.is_own);
      object.emission_date = checkService.getEmissionDate(this.money_account.bank, element, object.is_own);
      object.payment_date = checkService.getPaymentDate(this.money_account.bank, element, object.is_own);

      if (object.is_own) {
        //El money account puede ser la cuenta de la cuenta exportaron el excel o no ya que la pueden tener conectada a otros bancos.
        object.bank = this.money_account.bank;
        object.money_account = this.money_account;
        object.emitter = this.money_account.emitter;
      } else {
        object.emitter_name = checkService.getEmitterName(this.money_account.bank, element, object.is_own);
        object.bank = checkService.getBank(this.money_account.bank, element, this.banks, object.is_own);
      }

      return object;
    },
    readExcel() {
      this.step++;
      this.list_bulk = [];
      this.envios_no_validos = 0;
      this.envios_creados = 0;
      try {
        readXlsxFile(this.excel, { sheet: 1 }).then((rows) => {
          rows.forEach((element, index) => {
            if (index >= checkService.getStartIndex(this.money_account.bank)) {
              var obj = {
                object: this.parseObject(element),
                msg: "",
                status: 0,
                status_create: false,
                loading: false,
              };

              this.list_bulk.push(obj);
            }
          });
        });
      } catch (error) {
        console.log(error);
        this.$toast.error("Ocurrio un error al leer el archivo excel.", "Error");
      }
    },
    async importar() {
      var index = 0;
      this.loading_create = true;
      for (const element of this.list_bulk) {
        //sin mensaje entonces es valido para crear
        if (this.list_bulk[index].msg == "" && !this.list_bulk[index].status_create && this.list_bulk[index].status == 0) {
          this.list_bulk[index].loading = true;
          let result = await this.createItem(this.list_bulk[index].object);

          this.list_bulk[index].loading = false;
          this.list_bulk[index].status = 1;
          this.list_bulk[index].status_create = result.status;
          this.list_bulk[index].msg = result.msg;
          if (result.status) {
            this.envios_creados++;
          }
        }

        index++;
      }

      this.loading_create = false;

      if (this.list_bulk.length - this.envios_no_validos == this.envios_creados) {
        this.$toast.success("Se crearon " + this.envios_creados + " movimientos bancarios correctamente.", "Ok");
        this.$emit("close");
        this.$emit("filter");
      } else {
        this.$toast.warning("Se crearon " + this.envios_creados + " movimientos bancarios, pero " + (this.list_bulk.length - this.envios_no_validos) + " no se pudieron crear.", "Precaucion");
        //this.$emit("close");
        this.$emit("filter");
      }
    },
    updatePagination(pagination) {
      this.pagination = pagination;
    },
    async createItem(item) {
      if (item.amount < 0) {
        item.amount = item.amount * -1;
      }
      return this.service.create(item);
    },
  },
};
</script>
