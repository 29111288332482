<template>
  <div>
    <Title :pages="[{ icon: 'file-invoice', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <v-btn color="primary" class="mb-5" dark @click="open_generate_comprobante = true">Crear Comprobante</v-btn>

      <v-card>
        <div class="row mb-2 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-5">
                <div class="col-12 col-md pt-0">
                  <v-menu
                    ref="desde_selected"
                    v-model="desde_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="desde_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="desde_selected" label="Desde" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="desde_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="desde_selected_open = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.desde_selected.save(desde_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-12 col-md pt-0">
                  <v-menu
                    ref="hasta_selected"
                    v-model="hasta_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="hasta_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="hasta_selected" label="Hasta" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="hasta_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="hasta_selected = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.hasta_selected.save(hasta_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col-12 col-md pt-0">
                  <v-text-field clearable v-model="voucher_number_selected" autocomplete="off" class="pt-0 mt-0" label="Nº Comprobante"></v-text-field>
                </div>

                <div class="col-12 col-md pt-0">
                  <v-combobox
                    v-model="cbte_tipo_selected"
                    autocomplete="off"
                    data-vv-as="cbte_tipo_selected"
                    name="cbte_tipo_selected"
                    item-text="Desc"
                    item-value="Id"
                    :items="cbte_tipos"
                    label="Tipo de Comprobante"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col-12 col-md pt-0">
                  <v-combobox
                    v-model="state_selected"
                    autocomplete="off"
                    data-vv-as="state_selected"
                    name="state_selected"
                    item-text="name"
                    item-value="id"
                    :items="states"
                    label="Estado"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-md pt-0">
                  <v-combobox
                    v-model="emitter_selected"
                    autocomplete="off"
                    data-vv-as="emitter_selected"
                    name="emitter_selected"
                    item-text="name"
                    item-value="id"
                    :items="emitters"
                    label="Emisor"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>
                <div class="col-12 col-md pt-0">
                  <v-combobox
                    v-model="receiver_selected"
                    autocomplete="off"
                    data-vv-as="receiver_selected"
                    name="receiver_selected"
                    item-text="name"
                    item-value="id"
                    :items="receivers"
                    label="Receptor"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col-12 col-md pt-0">
                  <v-combobox v-model="client_selected" autocomplete="off" item-text="name" item-value="id" :items="clients" label="Cliente" class="pt-0 mt-0" clearable></v-combobox>
                </div>

                <div class="col-12 col-md pt-0">
                  <v-combobox
                    v-model="sub_cost_center_selected"
                    autocomplete="off"
                    data-vv-as="sub_cost_center_selected"
                    name="sub_cost_center_selected"
                    item-text="label"
                    item-value="id"
                    :items="costs_centers"
                    label="Centro de Costos"
                    class="pt-0 mt-0"
                    clereable
                  ></v-combobox>
                </div>

                <div class="col-12 col-md pt-0" style="display: flex; justify-content: end">
                  <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn color="blue-grey mr-2" dark v-bind="attrs" v-on="on"> Exportar <font-awesome-icon class="ml-2" icon="caret-down" /></v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="exportToExcel">
                        <v-list-item-title>Exportar listado</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="exportLibroIVA">
                        <v-list-item-title>Exportar libro IVA</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <h5 v-if="list_access" style="padding: 10px; margin-top: 0px; background: #222d32; color: #fff; font-weight: bold">
        <v-chip class="mx-1 my-0" color="white" outlined pill v-if="list_access"> Total S/IVA: ${{ new Intl.NumberFormat("en-EN").format(total) }} </v-chip>

        <v-chip class="mx-1 my-0" color="white" outlined pill v-if="list_access"> Total IVA: ${{ new Intl.NumberFormat("en-EN").format(parseFloat(total_iva).toFixed(2)) }} </v-chip>
      </h5>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.cbte_fch }}</td>
              <td>{{ props.item.cbte_tipo_name }}</td>
              <td>{{ props.item.voucher_number != null ? props.item.pto_vta.toString().padStart(4, "0") + "-" + props.item.voucher_number.toString().padStart(8, "0") : "-" }}</td>
              <td>{{ props.item.emitter.name }}</td>
              <td>{{ props.item.receiver.name }}</td>
              <td>{{ props.item.mon_name }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.total_ars) }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.total_usd) }}</td>
              <td>{{ props.item.state.name }}</td>

              <td class="text-center px-0">
                <v-tooltip v-if="showCbteAsociado(props.item)" bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="viewcbtesAsoc(props.item)"> info </v-icon>
                  </template>
                  <span>Comprobantes asociados</span>
                </v-tooltip>
              </td>

              <td v-if="props.item.charged" class="text-center"><font-awesome-icon icon="check" /></td>
              <td v-else class="text-right">
                <span :class="getPendingToChargeClass(props.item)">-{{ !props.item.is_credit_note && props.item.state.id != 2 ? formatUtils.formatPrice(props.item.pending_to_charged) : "-" }}</span>
              </td>

              <td class="text-right px-0 pr-3" style="min-width: 200px">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="sendEmail(props.item)"> mdi-email </v-icon>
                  </template>
                  <span>Enviar email</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="editItem(props.item)"> edit </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
                <v-tooltip bottom v-if="props.item.state.id == 0">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="openDialogSendToAFIP(props.item)"> send </v-icon>
                  </template>
                  <span>Enviar a la AFIP</span>
                </v-tooltip>

                <v-tooltip bottom v-if="!props.item.is_credit_note && props.item.state.id == 1 && props.item.state != 2 && !props.item.charged">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="green" class="mr-2" @click="openCharge(props.item)"> payments </v-icon>
                  </template>
                  <span>Cobrar</span>
                </v-tooltip>

                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small color="purple" class="mr-2" v-bind="attrs" v-on="on"> print </v-icon>
                  </template>

                  <v-list>
                    <v-list-item dense @click="printItem(props.item)">
                      <v-list-item-title>Descargar comprobante</v-list-item-title>
                    </v-list-item>

                    <v-list-item dense @click="printItem(props.item, true)">
                      <v-list-item-title>Ver comprobante</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-tooltip bottom v-if="props.item.state.id == 1 && props.item.is_invoice">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="red" class="mr-2" @click="cancelInvoice(props.item)"> close </v-icon>
                  </template>
                  <span>Anular</span>
                </v-tooltip>

                <v-tooltip bottom v-if="props.item.state.id == 0 || ([901, 902, 903].includes(props.item.cbte_tipo.Id) && props.item.imp_credit_accumulated == 0)">
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="delete_access" v-on="on" small color="red" class="mr-2" @click="deleteItem(props.item)"> delete </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <DialogGenerarComprobante
        title="Generar Comprobante"
        :open="open_generate_comprobante"
        :invoice_to_cancel="invoice_to_cancel"
        @closeDialog="closeComprobante"
        @reload="filter"
        @openModalRemito="openModalRemito"
        @updateMainItem="updateMainItem"
        :item="mainItem"
      />

      <DialogYesNo
        :open="dialog_settings"
        nameCancel="Cancelar"
        nameAccept="IR A CONFIGURAR"
        title="Enviar Email"
        message="Para poder enviar correos electrónicos es necesario que primero completes tu configuración de email."
        @cancel="dialog_settings = false"
        @accept="handleGoSettings"
        :maxWidth="500"
        @reload="filter"
      />

      <DialogYesNo :title="title_dialog" :message="message_dialog" :open="dialog_open_afip" :loading="loading_dialog" @accept="sendToAFIP" @cancel="closeDialog" @reload="filter" />

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" @reload="filter" />

      <CbtesAsocModal
        :open="dialog_cbtes_asoc"
        :item="voucher_selected"
        @cancel="
          dialog_cbtes_asoc = false;
          voucher_selected = null;
        "
        :can_disassociate="update_access"
        :service="service"
        @reload="filter"
      />

      <DialogSendEmail
        v-if="update_access"
        :item="invoice_send_email"
        :open="dialog_send_email"
        :formBtn="formBtn"
        :dialog="dialog_send_email"
        @closeDialog="closeSendEmailDialog"
        title="ENVIAR EMAIL"
        :loading="loading_send_email"
      />

      <ModalCharged v-if="update_access" :voucher="invoice_to_charge" :openFromVoucher="true" :formBtn="formBtn" :dialog="dialog_charged" @closeDialog="closeChargeDialog" />

      <ModalRemito v-if="update_access && dialog_remito" :voucher="voucherForRemito" :formBtn="formBtn" :dialog="dialog_remito" @closeDialog="cancelModalRemito" @updateItem="(newItem) => {}" />
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { remitoService } from "@/libs/ws/remitoService";
import { generalService } from "@/libs/ws/generalService";
import { clientsService } from "@/libs/ws/clientsService";
import { vouchersService } from "@/libs/ws/vouchersService";
import { emmiterService } from "@/libs/ws/emmiterService";
import { billingGeneralService } from "@/libs/ws/billingGeneralService";
import { receiversService } from "@/libs/ws/receiversService";
import { formatUtils } from "@/libs/utils/formatUtils";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import SettingsMixin from "@/mixins/SettingsMixin.vue";
import DialogGenerarComprobante from "./DialogGenerarComprobante.vue";
import DialogYesNo from "@/components/DialogYesNo";
import DialogDelete from "@/components/DialogDelete.vue";
import CbtesAsocModal from "@/components/Comprobantes/CbtesAsocModal.vue";
import ModalCharged from "@/views/Charges/ModalCharged.vue";
import ModalRemito from "@/views/Remitos/ModalRemito.vue";
import DialogSendEmail from "@/views/Billing/Vouchers/DialogSendEmail.vue";

export default {
  components: { Title, DialogGenerarComprobante, DialogYesNo, DialogDelete, CbtesAsocModal, ModalCharged, ModalRemito, DialogSendEmail },
  mixins: [GenericMixin, RolMixin, SettingsMixin],
  data() {
    return {
      voucherForRemito: null,
      formatUtils: formatUtils,
      parent: this,
      dialog_charged: false,
      service: vouchersService,
      page_title: "Comprobantes de Facturación",
      modal_title: "Comprobante",
      mainItem: {
        id: "",
        creation_date: "",
        cbte_tipo: "",
        emitter: "",
        receiver: "",
        imp_tot_conc: "",
        imp_neto: "",
        imp_total: "",
        dolar_cotiz: null,
      },
      dialog_delete: false,
      dialog_open_afip: false,
      dialog_cbtes_asoc: false,
      voucher_selected: null,
      loading_dialog: false,
      title_dialog: "",
      message_dialog: "",
      item_dialog: null,
      defaultItem: null,
      headers: [
        { text: "Fecha", align: "left", sortable: true, value: "cbte_fch" },
        { text: "Comprobante", align: "left", sortable: true, value: "cbte_tipo_name" },
        { text: "Nº Comprobante", align: "left", sortable: true, value: "voucher_number" },
        { text: "Emisor", align: "left", sortable: true, value: "emitter.name" },
        { text: "Receptor", align: "left", sortable: true, value: "receiver.name" },
        { text: "Moneda", align: "left", sortable: true, value: "mon_name" },
        { text: "Total ARS", align: "center", sortable: true, value: "imp_total" },
        { text: "Total USD", align: "center", sortable: true },
        { text: "Estado", align: "left", sortable: true, value: "state" },
        { text: "Cbtes Asoc.", align: "left", sortable: true },
        { text: "Cobrada", align: "center", sortable: true },
        { text: "Accion", value: "", align: "center" },
      ],
      list: [],
      cbte_tipos: [],
      states: [],
      emitters: [],
      receivers: [],
      clients: [],
      costs_centers: [],
      open_generate_comprobante: false,
      desde_selected_open: false,
      hasta_selected_open: false,
      voucher_number_selected: null,
      client_selected: null,
      sub_cost_center_selected: null,
      emitter_selected: null,
      receiver_selected: null,
      state_selected: null,
      cbte_tipo_selected: null,
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      invoice_to_cancel: null,
      invoice_to_charge: null,
      total: 0.0,
      total_iva: 0.0,
      dialog_remito: false,
      dialog_send_email: false,
      invoice_send_email: null,
      loading_send_email: false,
      dialog_settings: false,
    };
  },
  mounted() {
    this.loadComboData();
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
    this.loadList(this.service, this.getFilterParams());
  },
  methods: {
    showCbteAsociado(invoice) {
      return !this.service.VoucherList().includes(invoice.cbte_tipo.Id);
    },
    updateMainItem() {
      this.mainItem = JSON.parse(JSON.stringify(this.defaultItem));
    },
    editItem(item) {
      this.errors.clear();
      this.editedIndex = this.list.indexOf(item);
      this.mainItem = JSON.parse(JSON.stringify(item)); //Object.assign({}, item);
      this.open_generate_comprobante = true;
    },
    handleGoSettings() {
      this.$router.push("/settings");
    },
    sendEmail(item) {
      if (this.settings.email_host || this.settings.email_port || this.settings.email_username || this.settings.email_password || this.settings.email_encryption) {
        this.errors.clear();
        this.editedIndex = this.list.indexOf(item);
        this.invoice_send_email = JSON.parse(JSON.stringify(item)); //Object.assign({}, item);
        this.dialog_send_email = true;
      } else {
        this.dialog_settings = true;
      }
    },
    closeComprobante() {
      this.open_generate_comprobante = false;
      this.invoice_to_cancel = null;
    },
    loadList(service, param = null) {
      this.loading = true;

      if (param == null) {
        param = this.getFilterParams();
      }

      this.open_filter = false;
      service
        .filter(param)
        .then((response) => {
          this.list = response.result;
          this.loading = false;
          this.getTotal();
          if (typeof this.selected !== "undefined") {
            this.selected = [];
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.loading = false;
        });
    },
    getTotal() {
      this.total = 0;
      this.total_iva = 0;

      if (this.list.length > 0) {
        this.list.forEach((element) => {
          let total_neto_item = 0.0;
          if (element.is_credit_note) {
            this.total_iva = (parseFloat(this.total_iva) - parseFloat(element.imp_iva)).toFixed(2);
            total_neto_item = parseFloat(element.imp_total) + parseFloat(element.imp_iva) + parseFloat(element.imp_trib); //Porque la NC total viene como negativo
          } else {
            this.total_iva = (parseFloat(this.total_iva) + parseFloat(element.imp_iva)).toFixed(2);
            total_neto_item = parseFloat(element.imp_total) - parseFloat(element.imp_iva) - parseFloat(element.imp_trib);
          }
          //nota de credito ya viene en negativo.
          this.total = (parseFloat(this.total) + total_neto_item).toFixed(2);
        });
      }
    },
    closeDialog() {
      this.item_dialog = null;
      this.title_dialog = "";
      this.message_dialog = "";
      this.loading_dialog = false;
      this.dialog_open_afip = false;
    },
    openDialogSendToAFIP(item) {
      this.title_dialog = "Envio a AFIP " + item.cbte_tipo_name;
      this.message_dialog = "¿Esta seguro que desea enviar el comprobante " + item.cbte_tipo_name + " del " + item.creation_date + " a la AFIP?";
      this.loading_dialog = false;
      this.item_dialog = item;
      this.dialog_open_afip = true;
    },
    getFilterParams() {
      const param = {
        from: this.desde_selected,
        to: this.hasta_selected,
        emitter: this.emitter_selected != null ? this.emitter_selected.id : null,
        receiver: this.receiver_selected != null ? this.receiver_selected.id : null,
        client: this.client_selected != null ? this.client_selected.id : null,
        sub_cost_center: this.sub_cost_center_selected != null ? this.sub_cost_center_selected.id : null,
        state: this.state_selected != null ? this.state_selected.id : null,
        voucher_number: this.voucher_number_selected,
        cbte_tipo: this.cbte_tipo_selected != null ? this.cbte_tipo_selected.Id : null,
      };
      return param;
    },
    filter() {
      this.loadList(this.service, this.getFilterParams());
    },
    loadComboData() {
      emmiterService.filter({ enabled: true }).then((response) => {
        this.emitters = response.result;
      });
      clientsService.filter().then((response) => {
        this.clients = response.result;
      });
      receiversService.filter({}).then((response) => {
        this.receivers = response.result;
      });
      billingGeneralService.cbteTiposInvoice(null, "all").then((response) => {
        if (response.status) {
          this.cbte_tipos = response.result;
        }
      });
      billingGeneralService.list_states().then((response) => {
        this.states = response.result;
      });
      generalService.setSection(Constant.SECTION_SUB_COSTS_CENTERS);
      generalService
        .filter()
        .then((response) => {
          this.costs_centers = response.result;
        })
        .catch((e) => {
          console.log("costs_centers.filter error");
          console.log(e);
        });
    },
    exportToExcel() {
      if (this.loading) return;
      this.loading = true;
      let param = this.getFilterParams();

      this.service
        .export_filter(param)
        .then((response) => {
          if (Object.prototype.toString.call(response) != "[object ArrayBuffer]" && !response.status) {
            this.$toast.error(response.msg, "Error");
            this.loading = false;
            return;
          }

          let blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          let link = document.createElement("a");
          //link.setAttribute('download', "export")
          link.download = "remitos.xlsx";
          link.href = window.URL.createObjectURL(blob);
          link.target = "_blank";
          link.click();
          this.loading = false;
        })
        .catch((e) => {
          console.log("service.export_filter error");
          console.log(e);
          this.loading = false;
        });
    },
    exportLibroIVA() {
      if (this.loading) return;
      this.loading = true;
      let param = this.getFilterParams();

      //Validate mandatory filters: emitter, from, to
      if (!param.emitter || !param.from || !param.to) {
        this.$toast.error("Debe seleccionar un emisor, fecha desde y fecha hasta para exportar el libro de IVA.", "Error");
        this.loading = false;
        return;
      }

      this.service
        .export_libro_iva_filter(param)
        .then((response) => {
          if (Object.prototype.toString.call(response) != "[object ArrayBuffer]" && !response.status) {
            this.$toast.error(response.msg, "Error");
            this.loading = false;
            return;
          }

          let blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          let link = document.createElement("a");
          //link.setAttribute('download', "export")
          link.download = "libro_iva.xlsx";
          link.href = window.URL.createObjectURL(blob);
          link.target = "_blank";
          link.click();
          this.loading = false;
        })
        .catch((e) => {
          console.log("service.export_libro_iva_filter error");
          console.log(e);
          this.loading = false;
        });
    },
    sendToAFIP() {
      this.loading_dialog = true;
      vouchersService
        .sendToAFIP(this.item_dialog.id)
        .then((response) => {
          if (response.status) {
            this.$toast.success(response.msg, "Success");
            this.loadList(this.service, this.getFilterParams());
            this.dialog_open_afip = false;
          } else {
            this.$toast.error(response.msg, "Error");
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          this.$toast.error(error);
        })
        .finally(() => {
          this.loading_dialog = false;
        });
    },
    printItem(item, open = false) {
      this.loading = true;
      vouchersService
        .print(item.id)
        .then((response) => {
          let nombre = item.pto_vta.toString().padStart(4, "0") + "-" + item.voucher_number.toString().padStart(8, "0");
          if (!generalService.downloadPdf(response, item.receiver.name + "_" + nombre + ".pdf", open)) {
            this.$toast.error(response.msg, "Error");
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          this.$toast.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteItemConfirm() {
      this.loading = true;
      vouchersService
        .remove({ id: this.deleteId })
        .then((response) => {
          if (response.status === true) {
            this.$toast.success(response.msg, "OK");
            this.loadList(this.service, this.getFilterParams());
          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.$toast.error(response.message, "Error");
            } else {
              this.$toast.error(response.msg, "Error");
            }
          }
        })
        .catch((e) => {
          console.log("vouchersService.filter error");
          console.log(e);
          this.$toast.error("Error exception", "Error");
        })
        .finally(() => {
          this.loading = false;
        });
      this.dialog_delete = false;
    },
    viewcbtesAsoc(item) {
      this.voucher_selected = item;
      this.dialog_cbtes_asoc = true;
    },
    cancelInvoice(item) {
      this.invoice_to_cancel = item;
      this.open_generate_comprobante = true;
    },
    openCharge(item) {
      this.invoice_to_charge = item;
      this.dialog_charged = true;
    },
    closeSendEmailDialog() {
      this.invoice_send_email = null;
      this.dialog_send_email = false;
    },
    closeChargeDialog() {
      this.invoice_to_charge = null;
      this.dialog_charged = false;
      this.loadList(this.service, this.getFilterParams());
    },
    getPendingToChargeClass(item) {
      const state_canceled = 2;
      return !item.is_credit_note && item.state.id != state_canceled ? "text-danger text-nowrap" : "";
    },
    printSyscore(item) {
      this.loading = true;
      remitoService
        .print_syscore_of_invoice(item.id)
        .then((response) => {
          let nombre = item.remito_label;
          if (!generalService.downloadPdf(response, item.receiver.name + "_" + nombre + ".pdf")) {
            this.$toast.error(response.msg, "Error");
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          this.$toast.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    printCustom(item) {
      this.loading = true;
      remitoService
        .print_custom_of_invoice(item.id)
        .then((response) => {
          let nombre = item.remito_label;
          if (!generalService.downloadPdf(response, item.receiver.name + "_" + nombre + ".pdf")) {
            this.$toast.error(response.msg, "Error");
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          this.$toast.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openModalRemito(item) {
      this.errors.clear();
      //this.mainItem = Object.assign({}, item);
      this.voucherForRemito = JSON.parse(JSON.stringify(item));
      this.dialog_remito = true;
    },
    cancelModalRemito() {
      this.voucherForRemito = null;
      this.dialog_remito = false;
    },
  },
};

// TODO agregar un boton de limpiar busqueda.
</script>
