<template>
  <v-card elevation="1" class="mb-4">
    <v-card-text>
      <h6 class="text-h6 black--text">Tributos</h6>
      <p v-if="!isViewModal" class="text-body-2">Percepciones especiales que se aplican a perfil fiscal</p>
      <v-row>
        <v-col md="12" v-if="!isViewModal && !isEditable()">
          <v-btn small color="blue-grey" class="text-white" @click="addTributeDetail()" >
            <v-icon big color="green"> add </v-icon>
            <span>Agregar item</span>
          </v-btn>
        </v-col>
        <v-col md="12" v-else>
          <v-label style="color: #da0f0f;">{{getMessageValidation()}}</v-label>
        </v-col>
        <v-col md="12">
          <v-data-table class="pb-4" :headers="header_tribute" :items="invoice.tributes" style="border: solid 1px #1c1c1c" :hide-default-footer="true" :items-per-page="100">
            <template v-slot:item="props">
              <tr>
                <td>
                  <v-combobox
                    :loading="loading_combos['tributos']"
                    v-model="props.item.tribute"
                    data-vv-as="tribute"
                    :name="'tribute_' + props.index"
                    item-text="Desc"
                    item-value="Id"
                    :items="tributos_list"
                    label="Tributo"
                    autocomplete="off"
                    v-validate="'required|combobox_required'"
                    :disabled="isViewModal || isEditable()"
                  ></v-combobox>
                  <span class="red--text">{{ errors.first('tribute_' + props.index) }}</span>
                </td>
                <td style="width: 40%">
                  <v-text-field
                    :disabled="isViewModal || isEditable()"
                    autocomplete="off"
                    :name="'descripcion_' + props.index"
                    placeholder="Descripción*"
                    dense
                    type="text"
                    v-model="props.item.descripcion"
                    class="mt-3"
                  />
                  <span class="red--text">{{ errors.first('descripcion_' + props.index) }}</span>
                
                </td>

                <!--
                <td>
                  <v-text-field :disabled="isViewModal" autocomplete="off" name="alic" placeholder="Alicuota*" dense v-validate="'decimal'" type="number" v-model="props.item.alic" class="mt-3" />
                  <span class="red--text">{{ errors.first("alic") }}</span>
                </td>
                <td>
                  <v-text-field
                    :disabled="isViewModal"
                    autocomplete="off"
                    name="base_imp"
                    placeholder="Base Imponible*"
                    dense
                    v-validate="'decimal'"
                    type="number"
                    v-model="props.item.base_imp"
                    class="mt-3"
                  />
                  <span class="red--text">{{ errors.first("base_imp") }}</span>
                </td>
                -->
                <td>
                  <v-text-field
                    :disabled="isViewModal || isEditable()"
                    autocomplete="off"
                    :name="'importe_' + props.index"
                    placeholder="Importe*"
                    dense
                    v-validate="'required|decimal'"                    
                    v-model="props.item.importe"
                    class="mt-3"
                  />
                  <span class="red--text">{{ errors.first('importe_' + props.index) }}</span>
                </td>
                <!--<td class="text-right">{{ formatUtils.formatPrice((props.item.alic / 100.0) * props.item.base_imp) }}</td>-->

                <td class="text-center">
                  <v-tooltip bottom v-if="!isViewModal && !isEditable()">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small color="red" @click="removeDetail(props.item, 'tribute')"> indeterminate_check_box </v-icon>
                    </template>
                    <span>Remover</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { spentService } from "@/libs/ws/spentService";
import { formatUtils } from "@/libs/utils/formatUtils";
export default {
  created() {
    this.$validator = this.validator;
  },
  components: {},
  props: {
    invoice: Object,
    loadItemsFromInvoiceCancel: Function,
    loading_combos: Object,
    validator: Object,
    purchase_order: {
      type: Boolean,
      default: false
    },
    isViewModal: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    header_tribute: [
      { text: "Tributo", value: "product", sortable: false },
      { text: "Descripción", value: "descripcion", sortable: false },
      { text: "Importe", value: "total", sortable: false },
      { text: "", value: "", sortable: false, class: "bg-grey" },
    ],
    tributos_list: [],
    formatUtils: formatUtils,
    prueba: []
  }),
  watch: {
 
  },
  mounted() {
    this.loadComboData();
  },
  methods: {
    getMessageValidation() {
      if(this.invoice.id > 0 && !this.isViewModal) { 
        if(this.invoice.purchase_order != null) {
          return "No se puede modificar los tributos ya que el comprobante tiene una orden de compra asociada."
        } 

        if(this.invoice.total_paid > 0 ) {
          return "No se puede modificar los tributos ya que el comprobante tiene pagos asociados."
        }

        if(this.invoice.imp_credit_accumulated > 0 ) {
          return "No se puede modificar los tributos ya que el comprobante tiene una nota de credito/debito asociada." 
        }

        if(!this.invoice.is_invoice && this.invoice.cbtes_asoc != null && this.invoice.cbtes_asoc.length>0) {
          return "No se puede modificar los tributos ya que el comprobante tiene comprobantes asociados."
        }
      }
    },
    isEditable() {
      return (this.invoice.id > 0 && this.invoice.purchase_order != null ) || this.invoice.total_paid > 0 || this.invoice.imp_credit_accumulated > 0 || (!this.invoice.is_invoice && this.invoice.cbtes_asoc != null && this.invoice.cbtes_asoc.length>0);
    },  
    loadComboData() {
      this.tributos_list = spentService.tributes();
      this.loading_combos["tributos"] = false;
    },
    addTributeDetail() {
      this.invoice.tributes.push({
        descripcion: "",
        alic: "",
        base_imp: 0,
        tribute: null,
      });
    },
    removeDetail(item, colection_name) {
      if (colection_name == "tribute") {
        const index_item = this.invoice.tributes.indexOf(item);
        if (index_item > -1) this.invoice.tributes.splice(index_item, 1);
      }
    },
  },
};
</script>
