<template>
  <div>
    <Title :pages="[{ icon: 'file-invoice', page: page_title.toUpperCase() }]"></Title>

    <v-container fluid>
      <v-btn color="primary" class="mb-5" dark @click="open_generate_order = true">Crear Orden</v-btn>

      <v-card>
        <div class="row mb-2 mt-0">
          <div class="col-md-12" v-if="list_access">
            <div class="col-md-12" style="background:white; elevation-0">
              <div class="row pt-5">
                <div class="col pt-0">
                  <v-menu
                    ref="desde_selected"
                    v-model="desde_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="desde_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="desde_selected" label="Desde" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="desde_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="desde_selected_open = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.desde_selected.save(desde_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col pt-0">
                  <v-menu
                    ref="hasta_selected"
                    v-model="hasta_selected_open"
                    :close-on-content-click="false"
                    :close-on-click="false"
                    :return-value.sync="hasta_selected"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    clearable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="hasta_selected" label="Hasta" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on" class="pt-0 mt-0"></v-text-field>
                    </template>
                    <v-date-picker v-model="hasta_selected" type="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="hasta_selected = false"> Cancel </v-btn>
                      <v-btn text color="primary" @click="$refs.hasta_selected.save(hasta_selected)"> OK </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="col pt-0">
                  <v-text-field clearable v-model="purchase_order_number_selected" autocomplete="off" class="pt-0 mt-0" label="Nº de Orden"></v-text-field>
                </div>

                <div class="col pt-0">
                  <v-combobox
                    v-model="state_selected"
                    autocomplete="off"
                    data-vv-as="state_selected"
                    name="state_selected"
                    item-text="name"
                    item-value="id"
                    :items="states"
                    label="Estado"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>
              </div>

              <div class="row">
                <div class="col pt-0">
                  <v-combobox
                    v-model="supplier_selected"
                    autocomplete="off"
                    data-vv-as="supplier_selected"
                    name="supplier_selected"
                    item-text="name"
                    item-value="id"
                    :items="suppliers"
                    label="Proveedor"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>
                <div class="col pt-0">
                  <v-combobox
                    v-model="receiver_selected"
                    autocomplete="off"
                    data-vv-as="receiver_selected"
                    name="receiver_selected"
                    item-text="name"
                    item-value="id"
                    :items="receivers"
                    label="Receptor"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col pt-0">
                  <v-combobox
                    v-model="sub_cost_center_selected"
                    autocomplete="off"
                    data-vv-as="sub_cost_center_selected"
                    name="sub_cost_center_selected"
                    item-text="label"
                    item-value="id"
                    :items="costs_centers"
                    label="Centro de Costos"
                    class="pt-0 mt-0"
                    clearable
                  ></v-combobox>
                </div>

                <div class="col pt-0" style="display: flex; justify-content: end">
                  <v-btn color="blue-grey mr-2" dark @click="exportToExcel"> Exportar </v-btn>

                  <v-btn color="info" class="" @click="filter">Buscar</v-btn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-card>

      <v-card v-if="list_access">
        <v-card-title>
          <span class="text-uppercase">{{ page_title }}</span>
          <v-spacer></v-spacer>
          <v-text-field v-model="search" append-icon="search" label="Buscar" single-line hide-details></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="list"
          :search="search"
          :loading="loading"
          loading-text="Cargando..."
          :options="{
            descending: true,
            itemsPerPage: 15,
          }"
          @pagination="updatePagination"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 50, -1],
          }"
        >
          <template v-slot:item="props">
            <tr>
              <td>{{ props.item.cbte_fch }}</td>
              <td>{{ props.item.purchase_order_number != null ? props.item.purchase_order_number.toString().padStart(8, "0") : "-" }}</td>
              <td>{{ props.item.supplier.name }}</td>
              <td>{{ props.item.receiver.name }}</td>
              <td>{{ props.item.external_reference != null ? props.item.external_reference : "-" }}</td>
              <td>{{ props.item.mon_name }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.total_ars) }}</td>
              <td class="text-right">{{ formatUtils.formatPrice(props.item.total_usd) }}</td>
              <td v-html="calculateDaysLeft(props.item.estimated_delvery_date, props.item)"></td>
              <td>{{ props.item.state.name }}</td>

              <td class="text-center px-0 pr-3" style="min-width: 160px">
                <v-tooltip bottom v-if="props.item.state.id == ordersState.STATE_PARTIALLY || props.item.state.id == ordersState.STATE_FINALISHED">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="viewComprobantes(props.item)"> article </v-icon>
                  </template>
                  <span>Ver facturas asociadas</span>
                </v-tooltip>

                <v-tooltip bottom v-if="props.item.state.id == ordersState.STATE_PARTIALLY || props.item.state.id == ordersState.STATE_PENDING">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" color="green" small class="mr-2" @click="generateSpentModal(props.item)">post_add</v-icon>
                  </template>
                  <span>Generar gasto</span>
                </v-tooltip>

                <v-tooltip bottom v-if="props.item.state.id == ordersState.STATE_PARTIALLY">
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="orange" class="mr-2" @click="openDialogEndPurchaseOrder(props.item)"> door_front </v-icon>
                  </template>
                  <span>Finalizar Orden</span>
                </v-tooltip>
                <!-- tiene alguna condicion para poder editar? -->
                <v-tooltip bottom v-if="props.item.state.id == ordersState.STATE_PENDING">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="blue" class="mr-2" @click="editItem(props.item)"> edit </v-icon>
                  </template>
                  <span>Editar</span>
                </v-tooltip>

                <v-tooltip bottom v-if="props.item.state.id != ordersState.STATE_CANCELED">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" small color="grey" class="mr-2" @click="editItemDelivered(props.item)"> local_shipping </v-icon>
                  </template>
                  <span>Editar entrega</span>
                </v-tooltip>

                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon small color="purple" class="mr-2" v-bind="attrs" v-on="on"> print </v-icon>
                  </template>

                  <v-list>
                    <v-list-item dense @click="printItem(props.item)">
                      <v-list-item-title>Descargar Orden de Compra</v-list-item-title>
                    </v-list-item>

                    <v-list-item dense @click="printItem(props.item, true)">
                      <v-list-item-title>Ver Orden de Compra</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                
                <v-tooltip bottom v-if="props.item.state.id == ordersState.STATE_PENDING">
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="update_access" v-on="on" small color="black" class="mr-2" @click="openDialogCancelPurchaseOrder(props.item)"> close </v-icon>
                  </template>
                  <span>Cancelar</span>
                </v-tooltip>

                <v-tooltip bottom v-if="props.item.state.id == ordersState.STATE_PENDING">
                  <template v-slot:activator="{ on }">
                    <v-icon v-if="delete_access" v-on="on" small color="red" class="mr-2" @click="deleteItem(props.item)"> delete </v-icon>
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              {{ "Busqueda sin resultados - " + search }}
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <DialogGenerarPurchaseOrder
        title="Generar Orden de Combra"
        :open="open_generate_order"
        :order_to_cancel="order_to_cancel"
        @closeDialog="closePurchaseOrder"
        @reload="filter"
        @updateMainItem="updateMainItem"
        :item="mainItem"
      />

      <DialogUpdateDelivered title="Editar fecha de entrega" :open="dialog_delivered" @closeDialog="closeUpdateDelivered" @reload="filter" @updateMainItem="updateMainItem" :item="mainItem" />

      <DialogYesNo
        :title="title_cancel_order"
        :message="message_cancel_order"
        :open="dialog_cancel_order"
        :loading="loading_dialog_order"
        @accept="cancelSaleOrder"
        @cancel="closeDialogCancelSaleOrder"
        @reload="filter"
      />
      <DialogYesNo
        :title="title_cancel_order"
        :message="message_cancel_order"
        :open="dialog_end_order"
        :loading="loading_dialog_order"
        @accept="endSaleOrder"
        @cancel="closeDialogEndSaleOrder"
        @reload="filter"
      />

      <DialogDelete :dialog_delete="dialog_delete" @updateDialogDelete="updateDialogDelete" @deleteItemConfirm="deleteItemConfirm" @reload="filter" />

      <DialogViewComprobantes title="Facturas asociadas" :show="dialog_view_comprobantes" :purchase_order_id="purchase_order_id" @closeDialog="closeViewComprobantes" />

      <DialogGenerarSpent
        :invoice_to_cancel="invoice_to_cancel"
        :open="open_generate_spent"
        @cancel="cancelModalSpent"
        @closeDialog="closeSpent"
        :title="'Crear Gasto'"
        @reload="filter"
        :purchase_order="mainItem"
        :isViewModal="false"
      />
    </v-container>
  </div>
</template>

<script>
import Constant from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";
import { ordersState } from "@/constants/ordersState";
import { purchaseOrderService } from "@/libs/ws/purchaseOrderService";
import { receiversService } from "@/libs/ws/receiversService";
import { formatUtils } from "@/libs/utils/formatUtils";
import GenericMixin from "@/mixins/GenericMixin.vue";
import Title from "@/components/Title.vue";
import RolMixin from "@/mixins/RolMixin.vue";
import DialogGenerarPurchaseOrder from "./DialogGenerarPurchaseOrder.vue";
import DialogDelete from "@/components/DialogDelete.vue";
import DialogYesNo from "@/components/DialogYesNo.vue";
import DialogGenerarSpent from "@/views/Spents/DialogGenerarSpent.vue";
import DialogViewComprobantes from "./DialogViewComprobantes.vue";
import DialogUpdateDelivered from "./DialogUpdateDelivered.vue";

export default {
  components: { Title, DialogGenerarPurchaseOrder, DialogUpdateDelivered, DialogViewComprobantes, DialogYesNo, DialogDelete, DialogGenerarSpent },
  mixins: [GenericMixin, RolMixin],
  data() {
    return {
      voucherForRemito: null,
      formatUtils: formatUtils,
      ordersState: ordersState,
      parent: this,
      service: purchaseOrderService,
      page_title: "Ordenes de Compra",
      modal_title: "Orden de Compra",
      mainItem: {
        id: "",
        creation_date: "",
        supplier: "",
        receiver: "",
        imp_tot_conc: "",
        imp_neto: "",
        imp_total: "",
        dolar_cotiz: null,
        purchase_order_number: null,
        external_reference: null,
        estimated_delvery_date: "",
        tributes: [],
        delivered: false,
      },
      dialog_delete: false,
      loading_dialog: false,
      title_cancel_order: "",
      message_cancel_order: "",
      dialog_cancel_order: false,
      dialog_end_order: false,
      loading_dialog_order: false,
      purchase_order_id_cancel: null,
      defaultItem: null,
      headers: [
        { text: "Fecha", align: "left", sortable: true, value: "cbte_fch" },
        { text: "Nº Orden", align: "left", sortable: true, value: "purchase_order_number" },
        { text: "Proveedor", align: "left", sortable: true, value: "supplier.name" },
        { text: "Receptor", align: "left", sortable: true, value: "receiver.name" },
        { text: "Ref. Externa", align: "left", sortable: true, value: "external_reference" },
        { text: "Moneda", align: "left", sortable: true, value: "mon_name" },
        { text: "Total ARS", align: "center", sortable: true, value: "imp_total" },
        { text: "Total USD", align: "center", sortable: true },
        { text: "Entrega", align: "left", sortable: true, value: "estimated_delvery_date" },
        { text: "Estado", align: "left", sortable: true, value: "state" },
        { text: "Accion", value: "", align: "center" },
      ],
      list: [],
      states: [],
      suppliers: [],
      receivers: [],
      clients: [],
      costs_centers: [],
      open_generate_order: false,
      desde_selected_open: false,
      hasta_selected_open: false,
      purchase_order_number_selected: null,
      sub_cost_center_selected: null,
      supplier_selected: null,
      receiver_selected: null,
      state_selected: null,
      desde_selected: new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().slice(0, 10),
      hasta_selected: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().slice(0, 10),
      order_to_cancel: null,
      invoice_to_cancel: null,
      total: 0.0,
      total_iva: 0.0,
      open_generate_spent: false,
      purchase_order_id: null,
      dialog_view_comprobantes: false,
      dialog_delivered: false,
    };
  },
  mounted() {
    this.loadComboData();
    this.defaultItem = JSON.parse(JSON.stringify(this.mainItem));
    this.loadList(this.service, this.getFilterParams());
  },
  methods: {
    editItemDelivered(item) {
      this.errors.clear();
      this.editedIndex = this.list.indexOf(item);
      this.mainItem = JSON.parse(JSON.stringify(item)); //Object.assign({}, item);
      this.dialog_delivered = true;
    },
    calculateDaysLeft(deliveryDate, item) {
      if (item.delivered) {
        return '<span class="entregada">Entregada</span>';
      }

      if (item.state == ordersState.STATE_CANCELED) {
        return '<span class="">-</span>';
      }
      const currentDate = new Date();
      const delivery = new Date(deliveryDate);

      const differenceInTime = delivery - currentDate;
      const daysLeft = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24)); // Convertir milisegundos a días

      if (daysLeft > 0) {
        return `<span class="por_vencer">Entrega en ${daysLeft} días</span>`;
      } else if (daysLeft === 0) {
        return '<span class="vence_hoy">Entrega hoy</span>';
      } else {
        return `<span class="vencida">Retraso de ${daysLeft * -1} días</span>`;
      }
    },
    viewComprobantes(item) {
      this.purchase_order_id = item.id;
      this.dialog_view_comprobantes = true;
    },
    closeViewComprobantes() {
      this.dialog_view_comprobantes = false;
    },
    cancelModalSpent() {
      this.open_generate_spent = false;
      this.invoice_to_cancel = false;
      this.mainItem = JSON.parse(JSON.stringify(this.defaultItem));
    },
    updateMainItem() {
      this.mainItem = JSON.parse(JSON.stringify(this.defaultItem));
    },
    editItem(item) {
      this.errors.clear();
      this.editedIndex = this.list.indexOf(item);
      this.mainItem = JSON.parse(JSON.stringify(item)); //Object.assign({}, item);

      this.open_generate_order = true;
    },
    closePurchaseOrder() {
      this.open_generate_order = false;
      this.order_to_cancel = null;
    },
    closeUpdateDelivered() {
      this.dialog_delivered = false;
    },
    loadList(service, param = null) {
      this.loading = true;

      if (param == null) {
        param = this.getFilterParams();
      }

      this.open_filter = false;
      service
        .filter(param)
        .then((response) => {
          this.list = response.result;
          this.loading = false;
          this.getTotal();
          if (typeof this.selected !== "undefined") {
            this.selected = [];
          }
        })
        .catch((e) => {
          console.log("service.filter error");
          console.log(e);
          this.loading = false;
        });
    },
    getTotal() {
      this.total = 0;
      this.total_iva = 0;

      if (this.list && this.list.length > 0) {
        this.list.forEach((element) => {
          this.total_iva = (parseFloat(this.total_iva) + parseFloat(element.imp_iva)).toFixed(2);

          //nota de credito ya viene en negativo.
          this.total = (parseFloat(this.total) + parseFloat(element.imp_total) - parseFloat(element.imp_iva) - parseFloat(element.imp_trib)).toFixed(2);
        });
      }
    },
    getFilterParams() {
      const param = {
        from: this.desde_selected,
        to: this.hasta_selected,
        supplier: this.supplier_selected != null ? this.supplier_selected.id : null,
        receiver: this.receiver_selected != null ? this.receiver_selected.id : null,
        sub_cost_center: this.sub_cost_center_selected != null ? this.sub_cost_center_selected.id : null,
        state: this.state_selected != null ? this.state_selected.id : null,
        purchase_order_number: this.purchase_order_number_selected,
      };
      return param;
    },
    filter() {
      this.loadList(this.service, this.getFilterParams());
    },
    loadComboData() {
      generalService.setSection(Constant.SECTION_SUPPLIERS);
      generalService
        .filter()
        .then((response) => {
          this.suppliers = response.result;
        })
        .catch((e) => {
          console.log("suppliers.filter error");
          console.log(e);
        });

      receiversService.filter({}).then((response) => {
        this.receivers = response.result;
      });
      generalService.setSection(Constant.SECTION_SUB_COSTS_CENTERS);
      generalService
        .filter()
        .then((response) => {
          this.costs_centers = response.result;
        })
        .catch((e) => {
          console.log("costs_centers.filter error");
          console.log(e);
        });

      purchaseOrderService.list_states().then((response) => {
        this.states = response.result;
      });
    },
    exportToExcel() {
      if (this.loading) return;
      this.loading = true;
      let param = this.getFilterParams();

      this.service
        .export_filter(param)
        .then((response) => {
          if (Object.prototype.toString.call(response) != "[object ArrayBuffer]" && !response.status) {
            this.$toast.error(response.msg, "Error");
            this.loading = false;
            return;
          }

          let blob = new Blob([response], {
            type: "application/vnd.ms-excel",
          });
          let link = document.createElement("a");
          //link.setAttribute('download', "export")
          link.download = "purchase_orders.xlsx";
          link.href = window.URL.createObjectURL(blob);
          link.target = "_blank";
          link.click();
          this.loading = false;
        })
        .catch((e) => {
          console.log("service.export_filter sale orders error");
          console.log(e);
          this.loading = false;
        });
    },
    printItem(item, open = false) {
      this.loading = true;
      purchaseOrderService
        .print(item.id)
        .then((response) => {
          let nombre = item.purchase_order_number.toString().padStart(8, "0");
          if (!generalService.downloadPdf(response, item.receiver.name + "_" + nombre + ".pdf", open)) {
            this.$toast.error(response.msg, "Error");
          }
        })
        .catch((error) => {
          console.log(error);
          console.log(error.response);
          this.$toast.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteItemConfirm() {
      this.loading = true;
      purchaseOrderService
        .remove({ id: this.deleteId })
        .then((response) => {
          if (response.status === true) {
            this.$toast.success(response.msg, "OK");
            this.loadList(this.service, this.getFilterParams());
          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.$toast.error(response.message, "Error");
            } else {
              this.$toast.error(response.msg, "Error");
            }
          }
        })
        .catch((e) => {
          console.log("purchaseOrderService.filter error");
          console.log(e);
          this.$toast.error("Error exception", "Error");
        })
        .finally(() => {
          this.loading = false;
        });
      this.dialog_delete = false;
    },

    cancelInvoice(item) {
      this.order_to_cancel = item;
      this.open_generate_order = true;
    },

    generateSpentModal(item) {
      this.editedIndex = this.list.indexOf(item);
      this.mainItem = JSON.parse(JSON.stringify(item));
      this.open_generate_spent = true;
    },
    closeSpent() {
      this.mainItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.open_generate_spent = false;
    },
    cancelSaleOrder() {
      this.loading_dialog_order = true;
      const param = {
        id: this.purchase_order_id_cancel,
      };
      purchaseOrderService
        .cancel_purchase_order(param)
        .then((response) => {
          if (response.status === true) {
            this.$toast.success(response.msg, "OK");
            this.loadList(this.service, this.getFilterParams());
            this.dialog_cancel_order = false;
          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.$toast.error(response.message, "Error");
            } else {
              this.$toast.error(response.msg, "Error");
            }
          }
        })
        .catch((e) => {
          console.log("purchaseOrderService.cancelSaleOrder error");
          console.log(e);
          this.$toast.error("Error exception", "Error");
        })
        .finally(() => {});
    },
    closeDialogCancelSaleOrder() {
      this.title_cancel_order = "";
      this.message_cancel_order = "";
      this.purchase_order_id_cancel = null;
      this.loading_dialog_order = false;
      this.dialog_cancel_order = false;
    },
    openDialogCancelPurchaseOrder(item) {
      this.title_cancel_order = "Orden de compra " + item.purchase_order_number;
      this.message_cancel_order = "¿Esta seguro que desea cancelar la orden de compra " + item.purchase_order_number + "?";
      this.loading_dialog_order = false;
      this.purchase_order_id_cancel = item.id;
      this.dialog_cancel_order = true;
    },
    openDialogEndPurchaseOrder(item) {
      this.title_cancel_order = "Orden de compra " + item.purchase_order_number;
      this.message_cancel_order = "¿Esta seguro que desea finalizar la orden de compra " + item.purchase_order_number + "?";
      this.loading_dialog_order = false;
      this.purchase_order_id_cancel = item.id;
      this.dialog_end_order = true;
    },
    endSaleOrder() {
      this.loading_dialog_order = true;
      const param = {
        id: this.purchase_order_id_cancel,
      };
      purchaseOrderService
        .end_purchase_order(param)
        .then((response) => {
          if (response.status === true) {
            this.$toast.success(response.msg, "OK");
            this.loadList(this.service, this.getFilterParams());
            this.dialog_end_order = false;
          } else {
            if (Object.prototype.hasOwnProperty.call(response, "message")) {
              this.$toast.error(response.message, "Error");
            } else {
              this.$toast.error(response.msg, "Error");
            }
          }
        })
        .catch((e) => {
          console.log("purchaseOrderService.end_sale_order error");
          console.log(e);
          this.$toast.error("Error exception", "Error");
        })
        .finally(() => {});
    },
    closeDialogEndSaleOrder() {
      this.title_cancel_order = "";
      this.message_cancel_order = "";
      this.purchase_order_id_cancel = null;
      this.loading_dialog_order = false;
      this.dialog_end_order = false;
    },
  },
};
</script>
