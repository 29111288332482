<template>
  <v-card elevation="1" class="mb-4" v-if="isProductCompany()">
    <v-card-text>
      <h6 class="text-h6 black--text">Stock</h6>
      <p class="text-body-2">Indique si el comprobante ingresa mercaderia al stock</p>

      <v-row>
        <v-col md="6">
          <v-radio-group data-vv-as="comprobante incluye stock" name="stock_spent" v-model="invoice.stock_spent" row @change="changeIncludeStock()" v-validate="'required'" :disabled="isViewModal">
            <v-radio label="Con stock" :value="true" color="primary"></v-radio>
            <v-radio label="Sin stock" :value="false" color="primary"></v-radio>
          </v-radio-group>
          <span class="red--text">{{ errors.first("stock_spent") }}</span>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col md="6" v-if="invoice.stock_spent != null && invoice.stock_spent">
          <v-combobox
            v-model="invoice.warehouse"
            data-vv-as="deposito"
            name="warehouse"
            item-text="name"
            item-value="id"
            :items="warehouses"
            label="Deposito*"
            :v-validate="isRequired()"
            autocomplete="off"
            :disabled="isViewModal"
            v-validate="'required|combobox_required'"
          ></v-combobox>
          <span class="red--text">{{ errors.first("warehouse") }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import Constant from "@/constants/sections";
import SettingsMixin from "@/mixins/SettingsMixin.vue";
import { generalService } from "@/libs/ws/generalService";
export default {
  created() {
    this.$validator = this.validator;
  },
  components: {},
  mixins: [SettingsMixin],
  props: {
    invoice: Object,
    isViewModal: Boolean,
    validator: Object,
  },
  data: () => ({
    warehouses: [],
  }),
  watch: {},
  mounted() {
    this.loadComboData();
  },
  methods: {
    changeIncludeStock() {
      if (!this.invoice.stock_spent) {
        this.invoice.warehouse = null;
      } else {
        //Auto select default
        this.warehouses.forEach((element) => {
          if (element.default_warehouse) {
            this.invoice.warehouse = element;
          }
        });
      }
    },
    isRequired() {
      return this.invoice.stock_spent ? "required" : "";
    },
    loadComboData() {
      generalService.setSection(Constant.SECTION_WAREHOUSES);
      generalService
        .filter({ enabled: true })
        .then((response) => {
          this.warehouses = response.result;
          this.loading = false;
        })
        .catch((e) => {
          console.log("categories.filter error");
          console.log(e);
          this.loading = false;
        });
    },
  },
};
</script>
