<template>
  <div>
    <v-dialog v-model="open" max-width="1300" @click:outside="close">
      <v-card class="background-voucher" style="background: rgb(221, 225, 230)">
        <v-card-title class="headline mb-4">{{ formBtn }} Orden de Compra</v-card-title>

        <v-card-text>
          <div class="row">
            <div class="col-md-12">
              <!--Datos generales-->
              <DatosGenerales
                :isViewModal="isViewModal()"
                :invoice_to_cancel="invoice_to_cancel"
                :purchase_order="purchase_order"
                :loading_combos="loading_combos"
                :open="open"
                :validator="$validator"
              />

              <!--Periodo a imputar-->
              <Periodo :isViewModal="isViewModal()" :purchase_order="purchase_order" :validator="$validator" />

              <!-- Compra con o sin stock -->
              <ConStock :isViewModal="isViewModal()" :purchase_order="purchase_order" :validator="$validator" />

              <!-- Items Voucher -->
              <ItemsSpent
                :isViewModal="isViewModal()"
                :invoice="purchase_order"
                :open="open"
                :loading_combos="loading_combos"
                @loadItemsFromInvoiceCancel="loadItemsFromInvoiceCancel"
                :validator="$validator"
              />

              <Observation :item="purchase_order" :isViewModal="isViewModal()" subtitle="Ingrese una observación" />

              <v-row>
                <!--Info Extra-->
                <InfoExtra :dialog="open" :item="purchase_order" :isViewModal="isViewModal()" minHeight="264px" :validator="$validator"/>
                <!--Totales-->
                <Totales :isViewModal="isViewModal()" :imp_iva="imp_iva" :imp_neto="imp_neto" :imp_tot_conc="imp_tot_conc" :imp_op_ex="imp_op_ex" :imp_total="imp_total" :imp_trib="imp_trib" />
              </v-row>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="close()"> Cancelar </v-btn>

          <v-btn v-if="!isViewModal()" color="green darken-1" text @click="generatePurchaseOrder" :loading="loading">
            {{ purchase_order != null && purchase_order.id > 0 ? "Editar" : "Generar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <DialogLoading :dialog="dialog_loading" text="Cargando..." />
  </div>
</template>
<script>
import { purchaseOrderService } from "@/libs/ws/purchaseOrderService";
import { vouchersService } from "@/libs/ws/vouchersService";
import { billingUtils } from "@/libs/utils/billingUtils";

import { formatUtils } from "@/libs/utils/formatUtils";
import DialogLoading from "@/components/DialogLoading.vue";
import SettingsMixin from "@/mixins/SettingsMixin.vue";

import DatosGenerales from "@/views/PurchaseOrder/PurchaseComponent/DatosGenerales.vue";
import Totales from "@/views/PurchaseOrder/PurchaseComponent/Totales.vue";
import ConStock from "@/views/PurchaseOrder/PurchaseComponent/ConStock.vue";
import ItemsSpent from "@/views/Spents/SpentComponent/ItemsSpent.vue";

import Periodo from "@/views/PurchaseOrder/PurchaseComponent/Periodo.vue";
import InfoExtra from "@/components/Comprobantes/InfoExtra.vue";
import Observation from "@/components/Comprobantes/Observation.vue";

export default {
  components: { DialogLoading, DatosGenerales, Periodo, InfoExtra, Totales, ConStock, ItemsSpent, Observation },
  mixins: [SettingsMixin],
  props: {
    title: String,
    checkbox_label: String,
    open: Boolean,
    invoice_to_cancel: {
      default: null,
      type: Object,
    },
    invoice_to_generate: {
      default: null,
      type: Object,
    },
    formBtn: String,
    closeDialog: Function,
    reload: Function,
    cancel: Function,
    item: Object,
    updateMainItem: Function,
  },
  data: () => ({
    loading: false,
    dialog_loading: false,
    formatUtils: formatUtils,
    checkbox: false,
    loading_voucher_asociado: false,
    error_search_cbte_asociado: false,
    purchase_order: {
      id: "",
      warehouse: null,
      stock_spent: null,
      voucher_number: null,
      dolar_cotiz: null,
      supplier: null,
      receiver: null,
      cbte_tipo: null,
      observation: "",
      cbte_fch: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      cbte_fch_serv_desde: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      cbte_fch_serv_hasta: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      fch_vto_pago: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      estimated_delvery_date: new Date(
        new Date().toLocaleDateString("en-US", {
          timeZone: "America/Buenos_Aires",
        })
      )
        .toISOString()
        .slice(0, 10),
      concepto: null,
      moneda: null,
      mon_cotiz: 1,
      items_custom: [],
      cbte_asoc: null,
      purchase_order_number: null,
      external_reference: null,
      delivered: false
    },
    //Se separaron de purchase_order para poder usar el watch sin hacer un bucle infinito
    imp_total: 0,
    imp_neto: 0,
    imp_tot_conc: 0,
    imp_op_ex: 0,
    imp_iva: 0,
    imp_trib: 0,
    defaultInvoice: null,
    loading_combos: {
      cbte_tipos_invoice: false,
      supplier: false,
      receivers: false,
      cbte_tipos: false,
      ptosvtas: false,
      conceptos: false,
      monedas: false,
    },
  }),
  created: function () {
    window.addEventListener("keydown", (e) => {
      if (e.key == "Escape") {
        this.close();
      }
    });
  },
  watch: {
    open: function (val) {
      if (this.item && this.item.id != "") {
        this.purchase_order = JSON.parse(JSON.stringify(this.item));

        this.imp_total = this.item.imp_total;
        this.imp_neto = this.item.imp_neto;
        this.imp_tot_conc = this.item.imp_tot_conc;
        this.imp_op_ex = this.item.imp_op_ex;
        this.imp_iva = this.item.imp_iva;
        this.imp_trib = this.item.imp_trib;
        this.imp_discount = this.item.imp_discount;
        this.purchase_order.items_custom = this.item.items_custom;
      }
    },
    purchase_order: {
      handler: function (val) {
        this.calculateTotal();
      },
      deep: true,
    },
    loading_combos: {
      handler: function (val) {
        if (this.isFinishedLoadingCombos()) {
          this.dialog_loading = false;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.defaultInvoice = JSON.parse(JSON.stringify(this.purchase_order));
  },
  methods: {
    isViewModal() {
      return this.formBtn == "";
    },
    isFinishedLoadingCombos() {
      return (
        this.loading_combos["cbte_tipos_invoice"] == false &&
        this.loading_combos["supplier"] == false &&
        this.loading_combos["receivers"] == false &&
        this.loading_combos["cbte_tipos"] == false &&
        this.loading_combos["ptosvtas"] == false &&
        this.loading_combos["conceptos"] == false &&
        this.loading_combos["monedas"] == false
        // this.loading_combos["ivas"] == false &&
        // this.loading_combos["tributes"] == false
      );
    },

    loadItemsFromInvoiceCancel() {
      let items = {};
      let invoice_sample = this.invoice_to_cancel;
      if (invoice_sample == null) invoice_sample = this.invoice_to_generate;

      if (invoice_sample != null) {
        invoice_sample.items_custom.forEach((item) => {
          this.purchase_order.items_custom.push({
            descripcion: item.descripcion,
            quantity: item.quantity, //No se aumenta la cantidad porque el total va por el precio
            price: item.price,
            sub_cost_center: item.sub_cost_center,
            iva: item.iva,
            total: item.total, //el total lo calcula el frontend con iva
          });
        });
      }
    },
    selectComboId(combo, id, property_id = "id") {
      if (combo != null && combo.length > 0) {
        for (let i = 0; i < combo.length; i++) {
          if (combo[i][property_id] == id) {
            return combo[i];
          }
        }
      }
      return null;
    },
    close() {
      this.purchase_order = JSON.parse(JSON.stringify(this.defaultInvoice));

      this.$emit("updateMainItem");
      this.$emit("closeDialog");
    },
    customValidate() {
      let valid = true;
      if (this.purchase_order.warehouse != null && !(this.purchase_order.warehouse.id > 0)) {
        this.$toast.error("Seleccione un depósito", "Error");
        valid = valid && false;
      }

      this.purchase_order.items_custom.forEach((item) => {
        if (item.product_presentation != null && !(item.product_presentation.id > 0)) {
          this.$toast.error("Seleccione un producto para ".item.descripcion, "Error");
          valid = valid && false;
        }

        if (item.sub_cost_center != null && !(item.sub_cost_center.id > 0)) {
          this.$toast.error("Seleccione un Subcentro de costo para ".item.descripcion, "Error");
          valid = valid && false;
        }
      });

      return valid;
    },
    generatePurchaseOrder() {
      this.$validator.validate().then((valid) => {
        if (valid && this.customValidate()) {
          this.loading = true;
          let data = this.getPurchaseOrderParams();

          if (this.purchase_order.id > 0) {
            purchaseOrderService
              .update(data)
              .then((response) => {
                if (response.status) {
                  this.$toast.success("La orden de compra se editó correctamente", "OK");
                  this.$emit("reload");
                  this.purchase_order = JSON.parse(JSON.stringify(this.defaultInvoice));

                  this.close();
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    if (Object.prototype.hasOwnProperty.call(response, "message")) {
                      this.$toast.error(response.message, "Error");
                    } else {
                      this.$toast.error(response.msg, "Error");
                    }
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            purchaseOrderService
              .create(data)
              .then((response) => {
                if (response.status) {
                  this.$toast.success("La orden de compra se generó correctamente", "OK");
                  this.$emit("reload");
                  this.purchase_order = JSON.parse(JSON.stringify(this.defaultInvoice));
                  this.close();
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    if (Object.prototype.hasOwnProperty.call(response, "message")) {
                      this.$toast.error(response.message, "Error");
                    } else {
                      this.$toast.error(response.msg, "Error");
                    }
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => {
                this.loading = false;
              });
          }
        } else {
          this.$toast.error("Complete los campos requeridos", "Error");
        }
      });
    },
    getPurchaseOrderParams() {
      let data = {
        id: this.purchase_order.id,

        warehouse_id: this.purchase_order.stock_spent ? this.purchase_order.warehouse.id : null,

        stock_spent: this.purchase_order.stock_spent,
        supplier_id: this.purchase_order.supplier.id,
        receiver_id: this.purchase_order.receiver.id,
        cbte_fch: this.purchase_order.cbte_fch,
        fch_vto_pago: this.purchase_order.fch_vto_pago,
        estimated_delvery_date: this.purchase_order.estimated_delvery_date,
        mon_id: this.purchase_order.moneda.Id,
        mon_cotiz: this.purchase_order.moneda.Id == "PES" ? 1 : this.purchase_order.mon_cotiz,
        dolar_cotiz: this.purchase_order.dolar_cotiz,

        external_reference: this.purchase_order.external_reference,
        imp_total: parseFloat(this.imp_total).toFixed(2), //Para enviar el mismo
        imp_neto: parseFloat(this.imp_neto).toFixed(2), //Para enviar el mismo
        imp_tot_conc: parseFloat(this.imp_tot_conc).toFixed(2), //Para enviar el mismo
        imp_iva: parseFloat(this.imp_iva).toFixed(2), //Para enviar el mismo
        imp_op_ex: parseFloat(this.imp_op_ex).toFixed(2), //Para enviar el mismo
        imp_trib: parseFloat(this.imp_trib).toFixed(2), //Para enviar el mismo
        purchase_order_number: this.purchase_order.purchase_order_number,
        items_custom_arr: [],
        observation: this.purchase_order.observation,
        delivered: this.purchase_order.delivered
      };

      if (this.purchase_order.shippings != undefined) {
        data.shippings = this.purchase_order.shippings;
      }

      this.purchase_order.items_custom.forEach((item) => {
        const item_data = this.calculateCustomeItemTotals(item);
        data.items_custom_arr.push({
          id: item.id,
          desc: item.descripcion,
          iva_id: item.iva != null ? item.iva.Id : null,
          iva_desc: item.iva != null ? item.iva.Desc : "",
          imp_neto: item_data.imp_neto,
          imp_iva: item_data.imp_iva,
          price: item.price,
          sub_cost_center: item.sub_cost_center,
          quantity: item.quantity,
          discount_per: parseFloat(item.discount_per),
          discount_imp: item.discount_imp,
          product_presentation: item.product_presentation != undefined ? item.product_presentation : null,
        });
      });

      if (this.invoice_to_generate != null) {
        //Viene de una integracion
        //Agrego indicadores de la integracion
        data.purchase_origin = this.invoice_to_generate.purchase_origin;
        data.external_reference = this.invoice_to_generate.external_reference;

        data.charge_cbte_fch = this.invoice_to_generate.charge_cbte_fch;
        data.spent_cbte_fch = this.invoice_to_generate.spent_cbte_fch;
        data.spent_imp_total = this.invoice_to_generate.spent_imp_total;
      }

      return data;
    },
    calculateCustomeItemTotals(item) {
      if (item.price == null || item.quantity == null)
        return {
          imp_neto: 0,
          imp_iva: 0,
        };

      let imp_neto = formatUtils.formatPriceNumber(item.quantity * item.price - item.discount_imp);
      const iva_id = item.iva != null ? item.iva.Id : null;
      let imp_iva =
        vouchersService.NoIVAList().includes(iva_id) || iva_id == null ? 0.0 : (formatUtils.formatPriceNumber(imp_neto) * formatUtils.formatPriceNumber(item.iva.Desc.replace("%", ""))) / 100;
      return {
        imp_neto: imp_neto,
        imp_iva: formatUtils.formatPriceNumber(imp_iva),
        discount_imp: item.discount_imp,
        total: formatUtils.formatPriceNumber(imp_neto) + formatUtils.formatPriceNumber(imp_iva),
      };
    },

    calculateTotal() {
      this.imp_total = 0.0;
      this.imp_neto = 0.0;
      this.imp_tot_conc = 0.0;
      this.imp_iva = 0.0;
      this.imp_op_ex = 0.0;
      this.imp_trib = 0.0;

      this.purchase_order.items_custom.forEach((item) => {
        //Update discount_imp
        if (item.price != null && item.quantity != null && item.discount_per != null) item.discount_imp = (item.price * item.quantity * item.discount_per) / 100;
        else item.discount_imp = 0;

        let item_totales_obj = this.calculateCustomeItemTotals(item);

        item_totales_obj["iva_id"] = item.iva == null ? null : item.iva.Id;

        let imp_neto = billingUtils.getTotalFor("imp_neto", item_totales_obj);
        let imp_tot_conc = billingUtils.getTotalFor("imp_tot_conc", item_totales_obj);
        let imp_op_ex = billingUtils.getTotalFor("imp_op_ex", item_totales_obj);
        let imp_iva = item_totales_obj.imp_iva;

        this.imp_tot_conc += imp_tot_conc;
        this.imp_iva += imp_iva;
        this.imp_op_ex += imp_op_ex;
        this.imp_neto += imp_neto;
        this.imp_total += imp_neto + imp_tot_conc + imp_op_ex + imp_iva;
      });
    },
  },
};
</script>
