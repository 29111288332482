<template>
  <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" max-width="600px">
    <v-card>
      <v-form v-on:submit.prevent="createItem">
        <v-card-title>
          <span class="headline">{{ modal_title }}</span>
        </v-card-title>

        <v-card-text>
          <!-- START FORM DIALOG -->
          <div class="row">
            <div class="col-md-12">
              <DatePickerEsp
                :initToday="true"
                label="Fecha*"
                :date="item.date"
                :isRequired="true"
                name="date"
                :isViewModal="isViewModal()"
                @setDate="
                  (date) => {
                    item.date = date;
                  }
                "
              />
            </div>

            <div class="col-md-12">
              <v-combobox
                v-model="item.money_account"
                data-vv-as="Cuenta monetaria"
                name="money_account"
                item-text="name"
                item-value="id"
                :items="money_accounts"
                label="Cuenta monetaria*"
                autocomplete="off"
                :v-validate="item.is_own ? 'required|combobox_required' : 'combobox'"
                :disabled="isViewModal()"
              ></v-combobox>
              <span class="red--text">{{ errors.first("money_account") }}</span>
            </div>

            <div class="col-md-12">
              <v-combobox
                v-model="item.sub_cost_center"
                data-vv-as="sub_cost_center"
                name="sub_cost_center"
                item-text="label"
                item-value="id"
                :items="sub_costs_centers"
                label="Sub Centro de Costos"
                autocomplete="off"
                v-validate="'combobox'"
                :disabled="isViewModal()"
              ></v-combobox>
            </div>

            <div class="col-md-12">
              <p class="mb-0"><b>Seleccione el tipo de movimiento</b></p>
              <v-radio-group v-model="item.movement_type" :disabled="isViewModal()">
                <v-radio label="Debe" value="D"></v-radio>
                <v-radio label="Haber" value="H"></v-radio>
              </v-radio-group>
            </div>

            <div class="col-md-12">
              <v-text-field
                autocomplete="off"
                name="importe"
                label="Importe*"
                :disabled="isViewModal()"
                placeholder=""
                :v-validate="!item.is_own ? 'required|decimal' : ''"
                type="number"
                v-model="item.amount"
              />
              <span class="red--text">{{ errors.first("importe") }}</span>
            </div>

            <div class="col-md-12">
              <v-text-field autocomplete="off" data-vv-as="Concepto" name="concept" label="Concepto" :disabled="isViewModal()" v-model="item.concept" />
              <span class="red--text">{{ errors.first("concept") }}</span>
            </div>

            <div class="col-md-12">
              <v-text-field autocomplete="off" data-vv-as="Referencia" name="reference" label="Referencia" :disabled="isViewModal()" v-model="item.reference" />
              <span class="red--text">{{ errors.first("reference") }}</span>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
          <v-btn color="success" text type="submit" v-if="!isViewModal()" :loading="loading">{{ formBtn }}</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Constant from "@/constants/sections";
import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";
import DatePickerEsp from "@/components/DatePickerEsp.vue";
import { generalService } from "@/libs/ws/generalService";

export default {
  mixins: [ModalGenericMixin],
  components: { DatePickerEsp },
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      show: false,
      money_accounts: [],
      sub_costs_centers: [],
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    this.loadComboData();
  },
  methods: {
    loadComboData() {
      generalService.setSection(Constant.SECTION_SUB_COSTS_CENTERS);
      generalService
        .filter({ only_childs: true })
        .then((response) => {
          this.sub_costs_centers = response.result;
        })
        .catch((e) => {
          console.log(e);
          this.$toast.error("Ocurrio un error al obtener los centros de costos.");
        });

      generalService.setSection(Constant.SECTION_MONEY_ACCOUNTS);
      generalService
        .filter({})
        .then((response) => {
          this.money_accounts = response.result;
          this.loading = false;
        })
        .catch((e) => {
          console.log("money_accounts.filter error");
          console.log(e);
          this.loading = false;
        });
    },
    isViewModal() {
      return this.formBtn == "";
    },
    customeValidations() {
      if (this.item.sub_cost_center != null && !(this.item.sub_cost_center.id > 0)) {
        this.$toast.error("Debe seleccionar un sub centro de costos", "Error");
        return false;
      }
      return true;
    },
    createItem(e) {
      this.$validator.validate().then((valid) => {
        if (valid && this.customeValidations()) {
          e.preventDefault();
          this.loading = true;

          if (this.item.id > 0) {
            this.service
              .update(this.item)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          } else {
            this.service
              .create(this.item)
              .then((response) => {
                console.log(response);
                if (response.status) {
                  this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                console.log(response);
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          }
        }
      });
    },
  },
};
</script>
