<template>
  <v-card elevation="1" class="mb-4">
    <v-card-text>
      <h6 class="text-h6 black--text">Datos generales</h6>
      <p class="text-body-2 mb-0">Ingrese el emisor de la factura, el receptor y el tipo de comprobante</p>

      <v-row class="mt-0">
        <v-col md="4" class="mt-2">
          <v-combobox
            v-model="purchase_order.supplier"
            data-vv-as="proveedor"
            name="supplier"
            item-text="name"
            item-value="id"
            :items="suppliers"
            label="Proveedor*"
            autocomplete="off"
            v-validate="'required|combobox_required'"
            :loading="loading_combos['suppliers']"
            :disabled="invoice_to_cancel != null || isViewModal"
            v-on:change="
              supplierChangeCombo($event);
            "
          ></v-combobox>
          <span class="red--text">{{ errors.first("supplier") }}</span>
        </v-col>

        <v-col md="4" class="mt-2">
          <v-combobox
            v-model="purchase_order.receiver"
            data-vv-as="receptor"
            name="receiver"
            item-text="name"
            item-value="id"
            :items="receivers"
            label="Receptor*"
            autocomplete="off"
            v-validate="'required|combobox_required'"
            :loading="loading_combos['receivers']"
            :disabled="invoice_to_cancel != null || isViewModal"
           
          ></v-combobox>
          <span class="red--text">{{ errors.first("receiver") }}</span>
        </v-col>
        <v-col md="4" class="mt-2">
          <v-text-field autocomplete="off"
            data-vv-as="orden de compra" label="Referencia externa" name="external_reference"
            v-model="purchase_order.external_reference" :disabled="invoice_to_cancel != null || isViewModal">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col md="3">
          <v-text-field
            autocomplete="off"
            data-vv-as="numero de orden"
            label="Numero Orden*"
            name="purchase_order_number"
            v-validate="'integer|required'"
            v-model="purchase_order.purchase_order_number"
            :loading="loading_purchase_order_number"
            :disabled="purchase_order.purchase_order_number == null || purchase_order.id > 0"

            >
          </v-text-field>
          <span class="red--text">{{ errors.first("purchase_order_number") }}</span>
        </v-col>
        <v-col md="3">
          <v-combobox
            :loading="loading_combos['monedas']"
            :disabled="invoice_to_cancel != null || monedas.length == 0 || isViewModal"
            v-model="purchase_order.moneda"
            data-vv-as="moneda"
            name="moneda"
            item-text="Desc"
            item-value="Id"
            :items="monedas"
            label="Moneda*"
            autocomplete="off"
            v-validate="'required|combobox_required'"
          ></v-combobox>
          <span class="red--text">{{ errors.first("moneda") }}</span>
        </v-col>

        <v-col md="3">
          <v-text-field
            v-if="purchase_order.moneda != null && purchase_order.moneda.Id !== 'PES'"
            autocomplete="off"
            data-vv-as="cotizacion de la moneda"
            label="Cotización de la moneda*"
            name="mon_cotiz"
            v-validate="'decimal|required'"
            v-model="purchase_order.mon_cotiz"
            :disabled="invoice_to_cancel != null || isViewModal"
          >
          </v-text-field>
          <span class="red--text">{{ errors.first("mon_cotiz") }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { emmiterService } from "@/libs/ws/emmiterService";
import { purchaseOrderService } from "@/libs/ws/purchaseOrderService";

import Constant from "@/constants/sections";

import { spentService } from "@/libs/ws/spentService";
import { generalService } from "@/libs/ws/generalService";

export default {
  created() {
    this.$validator = this.validator;
  },
  components: {},
  props: {
    open: Boolean,
    purchase_order: Object,
    invoice_to_cancel: Object,
    loading_combos: Object,
    validator: Object,
    isViewModal: {
      type: Boolean,
      default: false,
    },

  },
  data: () => ({
    suppliers: [],
    receivers: [],
    monedas: [],
    loading_purchase_order_number:false

  }),
  watch: {
    open: function (val) {
      if (val) {
        this.errors.clear();
        this.$validator.reset();
        this.loadComboData();
      }
    },
  },
  mounted() {
    this.loadComboData();
  },
  methods: {
    getNextNumPurchaseOrder() {

      if (this.purchase_order.receiver != null && this.purchase_order.receiver.id > 0 && !this.isViewModal && this.purchase_order.id == 0) {
        if (this.loading_purchase_order_number) return;

        this.loading_purchase_order_number = true;

        purchaseOrderService
          .get_next_number(this.purchase_order.receiver.id)
          .then((response) => {
            if (response.status) {
              this.purchase_order.purchase_order_number = response.result;
            }
          })
          .finally(() => {
            this.loading_purchase_order_number = false;
          });
      }
    },
    receiver_name: (item) => (item == null ? "" : item.name + " (" + item.client.name + ")"),
    loadComboData() {
      this.loading_combos["suppliers"] = true;
      generalService.setSection(Constant.SECTION_SUPPLIERS);
      generalService
        .filter()
        .then((response) => {
          this.suppliers = response.result;
        })
        .finally(() => {
          this.loading_combos["suppliers"] = false;
        });

      this.loading_combos["receivers"] = true;
      emmiterService
        .filter({ enabled: true })
        .then((response) => {
          this.receivers = response.result;
          if (response.result.length == 1) {
            this.purchase_order.receiver = response.result[0];
            this.getNextNumPurchaseOrder();
          }
        })
        .finally(() => {
          this.loading_combos["receivers"] = false;
        });

      this.monedas = spentService.getMonedas();
      this.purchase_order.moneda = this.monedas[0];
      if (this.invoice_to_cancel != null) this.selectComboId(this.monedas, this.invoice_to_cancel.moneda, "Id");
    },

    supplierChangeCombo(event) {
      this.dialog_loading = true;
      if (this.purchase_order.supplier != null && this.purchase_order.supplier.id) {
        // this.getNextNumPurchaseOrder();

        // this.loading_combos["monedas"] = true;
        // emmiterService.monedas(this.purchase_order.supplier.id).then((response) => {
        //   if (response.status) {
        //     this.monedas = response.result;
        //     this.loading_combos["monedas"] = false;
        //     this.purchase_order.moneda = response.result[0];
        //     if (this.order_to_cancel != null) this.selectComboId(this.monedas, this.order_to_cancel.moneda, "Id");
        //   }
        // });
      }
    },
    selectComboId(combo, id, property_id = "id") {
      if (combo != null && combo.length > 0) {
        for (let i = 0; i < combo.length; i++) {
          if (combo[i][property_id] == id) {
            return combo[i];
          }
        }
      }
      return null;
    },
 
  },
};
</script>
