import Vue from "vue";
import VeeValidate, { Validator } from "vee-validate";
import es from "vee-validate/dist/locale/es";
Vue.use(VeeValidate);
Validator.localize("es", es);

Validator.extend('combobox_required', {
    getMessage: field => 'Seleccione una opción del listado',
    validate: value => value && ( value.id != null || value.id != undefined || value.Id != null || value.Id != undefined)
});

Validator.extend('combobox', {
    getMessage: field => 'Seleccione una opción del listado',
    validate: value => (value && ( value.id > 0 || value.Id > 0 )) || value == null
});

export default VeeValidate;
