<template>
  <v-card elevation="1" class="mb-4">
    <v-card-text>
      <h6 class="text-h6 black--text">Productos o servicios</h6>
      <p v-if="!isViewModal" class="text-body-2">Ingrese los productos o servicios que va incluir en el remito</p>

      <v-row>
        <v-col md="12" v-if="!isViewModal">
          <v-btn small color="blue-grey" class="pl-4 text-white" @click="addCustomDetail()">
            <v-icon big color="green"> add </v-icon>
            <span>Agregar item</span>
          </v-btn>
        </v-col>

        <v-col md="12" class="px-4 pb-4">
          <v-data-table :headers="header_custome" :items="remito.items" style="border: solid 1px #1c1c1c" :hide-default-footer="true" :items-per-page="100">
            <template v-slot:item="props">
              <tr>
                <td style="width: 40%">
                  <v-text-field
                    :disabled="isViewModal"
                    autocomplete="off"
                    :name="'descripcion_' + props.index"
                    placeholder="Descripción*"
                    dense
                    type="text"
                    v-model="props.item.descripcion"
                    class="mt-3"
                  />
                  <span class="red--text">{{ errors.first("descripcion_" + props.index) }}</span>
                </td>
                <td style="width: 40%">
                  <v-combobox
                    v-model="props.item.product_presentation"
                    data-vv-as="Producto"
                    :name="'product_presentation_' + props.index"
                    :item-text="label"
                    item-value="id"
                    :items="presentations"
                    label="Producto"
                    autocomplete="off"
                    v-on:change="productChangeCombo($event, props.index)"
                    :disabled="isViewModal"
                    v-validate="'required|combobox_required'"
                  ></v-combobox>
                  <span class="red--text">{{ errors.first("product_presentation_" + props.index) }}</span>
                </td>
                <td style="width: 50px">
                  <v-text-field
                    data-vv-as="Cantidad"
                    :disabled="isViewModal"
                    autocomplete="off"
                    :name="'quantity_' + props.index"
                    placeholder="Cantidad*"
                    dense
                    v-validate="'decimal'"
                    type="number"
                    v-model="props.item.quantity"
                    class="mt-3"
                  />
                  <span class="red--text">{{ errors.first("quantity_" + props.index) }}</span>
                </td>

                <td class="text-center">
                  <v-tooltip bottom v-if="!isViewModal">
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" small color="red" @click="removeDetail(props.item, 'custom')"> indeterminate_check_box </v-icon>
                    </template>
                    <span>Remover</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Constant from "@/constants/sections";
import ConstantSettings from "@/constants/settings";
import { formatUtils } from "@/libs/utils/formatUtils";
import { generalService } from "@/libs/ws/generalService";
import { vouchersService } from "@/libs/ws/vouchersService";
import { emmiterService } from "@/libs/ws/emmiterService";
import SettingsMixin from "@/mixins/SettingsMixin.vue";
export default {
  created() {
    this.$validator = this.validator;
  },
  components: {},
  props: {
    open: Boolean,
    remito: Object,
    validator: Object,
    isViewModal: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [SettingsMixin],
  data: () => ({
    presentations: [],
    ivas: [],
    emitter_selected: null,
    header_custome: [
      { text: "Descripción", value: "descripcion", sortable: false },
      { text: "Producto", value: "product_presentation", sortable: false },
      { text: "Cantidad", value: "quantity", sortable: false, class: "bg-grey" },
      { text: "", value: "", sortable: false, class: "" },
    ],
  }),
  watch: {
    open: function (val) {
      if (val) {
        this.errors.clear();
        this.$validator.reset();
        this.loadComboData();
      }
    },
    remito: {
      handler: function (newValue) {},
      deep: true,
    },
  },
  mounted() {
    this.loadComboData();
  },
  methods: {
    label(item) {
      if (this.isProductCompany()) return item.label;
      return item.name;
    },
    loadComboData() {
      generalService.setSection(Constant.SECTION_PRODUCTS);
      generalService
        .query({ sellable: true }, Constant.SECTION_PRODUCTS + "/presentations")
        .then((response) => {
          this.presentations = response.result;
        })
        .catch((e) => {
          console.log("presentations.filter error");
        });
    },
    addCustomDetail() {
      this.remito.items.push({
        descripcion: "",
        quantity: 1,
        product_presentation: null,
      });
    },
    productChangeCombo(e, index) {
      if (this.remito.items[index].descripcion == "" && this.label(e) != null) this.remito.items[index].descripcion = this.label(e);
    },
    removeDetail(item, colection_name) {
      if (colection_name == "custom") {
        const index_item = this.remito.items.indexOf(item);
        if (index_item > -1) this.remito.items.splice(index_item, 1);
      }
    },
  },
};
</script>
