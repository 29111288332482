<template>
  <v-layout>
    <v-dialog v-bind:value="dialog" @click:outside="$emit('updateDialog', false)" max-width="500px">
      <template v-slot:activator="{ on }">
        <v-btn color="primary" class="mb-5" dark v-on="on" @click="$emit('updateDialog', true)">{{ button_name }}</v-btn>
      </template>

      <v-card>
        <v-form v-on:submit.prevent="createItem">
          <v-card-title>
            <span class="headline">{{ modal_title }}</span>
          </v-card-title>

          <v-card-text>
            <!-- START FORM DIALOG -->
            <div class="row">
              <div class="col-md-12">
                <v-combobox
                  v-model="item.category"
                  data-vv-as="rubro"
                  name="category"
                  item-text="name"
                  item-value="id"
                  :items="categories"
                  label="Rubro*"
                  autocomplete="off"
                  v-validate="'required|combobox_required'"
                  :disabled="isViewModal()"
                ></v-combobox>
                <span class="red--text">{{ errors.first("category") }}</span>

                <v-text-field data-vv-as="nombre" label="Nombre*" name="name" v-model="item.name" v-validate="'required'" :disabled="isViewModal()"></v-text-field>
                <span class="red--text">{{ errors.first("name") }}</span>

                <!--<v-textarea outlined v-model="item.description" data-vv-as="description" name="description" label="Descripción" :disabled="isViewModal()"></v-textarea>-->
              </div>
            </div>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click="$emit('updateDialog', false)">Cerrar</v-btn>
            <v-btn color="success" text type="submit" v-if="!isViewModal()" :loading="loading">{{ formBtn }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import ModalGenericMixin from "@/mixins/ModalGenericMixin.vue";
import ConstantSections from "@/constants/sections";
import { generalService } from "@/libs/ws/generalService";

export default {
  mixins: [ModalGenericMixin],
  props: {
    parent: Object,
    service: Object,
    modal_title: String,
    dialog: Boolean,
    item: Object,
    button_name: String,
    formBtn: String,
  },
  data() {
    return {
      show: false,
      categories: [],
    };
  },
  watch: {
    item() {
      this.$emit("updateItem", this.item);
    },
    dialog() {
      this.errors.clear();
    },
  },
  mounted() {
    generalService.filter({}, ConstantSections.SECTION_CATEGORIES).then((response) => {
      console.log(response.result);
      this.categories = response.result;
    });
  },
  methods: {
    isViewModal() {
      return this.formBtn == "";
    },
    createItem(e) {
      if (this.loading) return;
      this.$validator.validate().then((valid) => {
        if (valid) {
          e.preventDefault();

          this.loading = true;
          if (this.item.id > 0) {
            this.service
              .update(this.item)
              .then((response) => {
                if (response.status == true) {
                  this.$toast.success("El " + this.modal_title + " se modifico correctamente.", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          } else {
            this.service
              .create(this.item)
              .then((response) => {
                console.log(response);
                if (response.status) {
                  this.$toast.success("El " + this.modal_title + " se creo correctamente", "OK");
                  this.$emit("updateDialog", false);
                  this.$emit("loadList");
                } else {
                  if (Object.prototype.hasOwnProperty.call(response, "message")) {
                    this.$toast.error(response.message, "Error");
                  } else {
                    this.$toast.error(response.msg, "Error");
                  }
                }
              })
              .catch((response) => {
                console.log(response);
                this.$toast.error(response.msg, "Error");
              })
              .finally(() => (this.loading = false));
          }
        }
      });
    },
  },
};
</script>
